import React from "react";

export const DotMenuScg: React.FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5 8C13.6 8 14.5 7.1 14.5 6C14.5 4.9 13.6 4 12.5 4C11.4 4 10.5 4.9 10.5 6C10.5 7.1 11.4 8 12.5 8ZM12.5 10C11.4 10 10.5 10.9 10.5 12C10.5 13.1 11.4 14 12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10ZM10.5 18C10.5 16.9 11.4 16 12.5 16C13.6 16 14.5 16.9 14.5 18C14.5 19.1 13.6 20 12.5 20C11.4 20 10.5 19.1 10.5 18Z"
                  />
        </svg>

    )
}