import {httpApi} from "@app/api/http.api";
import {UserDomain} from "@app/domains/user.domain";

export interface UpdateResult {
    success: boolean,
    message: string,
    code: number,
    data: UserDomain
}

export interface ChangePasswordRequest {
    currentPassword: string,
    newPassword: string,
    reNewPassword: string
}

export const UserAccountUpdateProfile = (formData: FormData): Promise<UpdateResult> =>
    httpApi.post<UpdateResult>('user-account/profile/update-profile', formData).then(({data}) => data);

export const UserAccountChangePassword = (request: ChangePasswordRequest): Promise<UpdateResult> =>
    httpApi.post<UpdateResult>('user-account/profile/change-password', request).then(({data}) => data);