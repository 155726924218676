import React from 'react';
import * as S from './styles.style';
import {useTranslation} from "react-i18next";

export const TextAreaComponent: React.FC<{
    error: string,
    value: string,
    onChange: any,
    rows?: number,
    placeholder?: string
}> = (props) => {

    const {t} = useTranslation()

    return (
        <S.Input>
            <textarea rows={props.rows ?? 10} onChange={props.onChange}
                      placeholder={props.placeholder}>{props.value}</textarea>
        </S.Input>
    );
};
