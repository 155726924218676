import styled from "styled-components";
import { Layout } from "antd";
import { media } from "@app/assets/styles/themes/constants";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;
  
  .box{
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xs}{
      width: 95%;
    }
    
    @media only screen and ${media.sm}{
      width: 95%;
    }
    
    @media only screen and ${media.md}{
      width: 95%;
    }

    @media only screen and ${media.xl}{
      width: 80%;
    }

    @media only screen and ${media.xxl}{
      width: 95%;
    }
  }
  
`;

export const Slider = styled(Layout)`
  background: var(--white);
  width: 80%;
  margin: 80px auto 30px auto;
  display: flex;
  justify-content: space-between;

  .col-1 {
    width: 70%;
    margin-right: 4rem;
    margin-top: 2rem;
    display: table;

    h3 {
      color: var(--black);
      font-size: 3rem;
    }

    .green {
      color: var(--primary-color);
      margin-top: 50px;
      font-size: 22px;
      font-family: "robotoMedium" !important;
    }

    p {
      color: var(--black);
      line-height: 28px;
      margin-top: 10px;
      font-size: 22px;
      font-family: "robotoRegular" !important;
    }
  }

  .col-2 {
    width: 30%;
    margin-left: 4rem;
    float: right;
    display: table;

    .image {
      width: 250px;
      height: 250px;
      border-radius: 12px;
    }

    .currentSlide {
      transition-duration: 0.5s;
      transition: ease;
      opacity: 0.2;
    }

    .currentSlide.active {
      opacity: 1;
      transition-duration: 0.5s;
      transform: scale(1.2);
    }
  }

  @media only screen and ${media.md} {
    .col-2 {
      display: table;
      margin-left: 2rem;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }

  @media only screen and ${media.sm} {
    width: 100%;
    .col-1 {
      width: 100%;
    }
    
    .col-2 {
      display: none;
    }
  }
  
  @media only screen and ${media.xs} {
    margin: 0 auto 0 auto;
    width: 100%;
    .col-1 {
      width: 100%;
      margin-right: 0;

      h3 {
        font-size: 2rem;
      }
    }

    .col-2 {
      display: none;
    }
  }
  
`;

export const Category = styled(Layout)`
  background: var(--light-green);
  width: 100%;
  margin: 60px auto 0 auto;
  padding: 20px;
  padding-bottom: 120px;
  border-radius: 15px;

  h3 {
    color: var(--black);
    font-size: 32px;
    margin-bottom: 15px;
  }

  p {
    color: var(--black);
    width: 70%;
    font-size: 22px;
    line-height: 28px;
  }

  @media only screen and ${media.sm}{
    h3 {
      font-size: 22px;
    }

    p {
      width: 100%;
      font-size: 14px;
    }
  }
  
`;

export const Categories = styled(Layout)`
  width: 80%;
  margin: -60px auto 0 auto;
  padding: 10px;
  background: var(--white);
  border-radius: 15px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30);

  .categories-container {
    display: flex;
    justify-content: space-between;
    margin-right: 88px;
    margin-left: 88px;
    margin-top: 10px;
    margin-bottom: 10px;

   > a {
      width: 110px;
      margin-right: 40px;
      margin-left: 40px;
      display: table;
      align-items: center;
      align-self: center;
      cursor: pointer;
     > a {
      width: 100%;
      display: flex;
      flex-direction: column;

      img {
        height: 120px;
        border-radius: 10px;
        text-align: center;
        align-items: center;
        align-self: center;
      }

      p {
        color: var(--black);
        text-align: center;
        font-size: 16px;
        line-height: 24px; /* 150% */
        margin-top: 10px;
      }

    }
    
      &:hover {
        opacity: 0.8;
      }
    }
       > a:first-child {
          margin-left: 0;
      }

       > a:last-child {
          margin-right: 0;
      }

    @media only screen and ${media.sm} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media only screen and ${media.xs} {
    width: 90%;

    div {
      margin-right: 0;
      margin-left: 0;
      overflow-x: scroll;
    }
  }
  
  @media only screen and ${media.sm} {
    width: 90%;

    div {
      margin-right: 0;
      margin-left: 0;
      overflow-x: scroll;
    }
  }

  @media only screen and ${media.xxl} {
    // width: 90%;

    div {
      margin-right: 0;
      margin-left: 0;
      overflow-x: scroll;
    }
  }
`;

export const Brands = styled(Layout)`
  margin-top: 50px;

  .green-box {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    background: var(--primary-color);
    border-radius: 10px;
  }

  .brand-title {
    display: flex;
    margin-top: -40px;
    color: var(--black);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    margin-left: 20px;
    height: 40px;
    font-family: "robotoMedium"!important;

    small {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-left: 10px;
      align-self: end;
      margin-bottom: 5px;
      font-family: "robotoMedium"!important;
    }
  }

  .brands {
    width: 96%;
    display: inline-flex;
    padding: 30px 50px 30px 50px;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 16px 16px 0;
    background: rgba(231, 255, 242, 0.50);

    a {
      img {
        width: 120px;
        height: 60px;
        border-radius: 3px;
        opacity: 1;
      }
    }

    @media only screen and ${media.sm}{
      overflow-x: scroll;
      padding: 15px 25px 15px 25px;

      a{
        img{
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    
    @media only screen and ${media.sm}{
      overflow-x: scroll;
      padding: 15px 25px 15px 25px;
      
      a{
        img{
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
`;

export const HotProducts = styled(Layout)`
  width: 100%;
  margin-top: 50px;

  .title {
    background: rgba(0, 82, 123, 0.5);
    border-radius: 0 8px 8px 0;
    display: inline-flex;
    padding: 6px 32px 6px 111px;
    justify-content: flex-end;
    align-items: center;

    p {
      color: var(--black);
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      font-family: "robotoMedium" !important;
      margin-left: 8rem;
    }

    @media only screen and ${media.sm} {
      padding: 6px 32px 6px 90px;

      p {
        margin-left: 1rem;
      }
    }

    @media only screen and ${media.xs} {
      padding: 6px 32px 6px 90px;

      p {
        margin-left: 0;
        font-size: 18px;
      }
    }

  }

  .slider {
    margin-left: 8%;
    overflow: hidden;

    @media only screen and ${media.xs} {
      margin-left: 0;
    }
    @media only screen and ${media.sm} {
      margin-left: 2%;
    }
  }

  .show-more {
    width: 210px !important;
    text-decoration: none;

    a {
      display: flex;
      width: 210px;
      margin: 0 auto 0 auto;
      align-self: center;
      padding: 10px 24px;
      align-items: center;
      border-radius: 8px;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      text-decoration: none;

      b svg {
        fill: rgba(0, 82, 123, 0.5);
        align-self: center;
      }

      P {
        color: rgba(0, 82, 123, 0.5);
        font-family: "robotoMedium" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      &:hover {
        background: var(--item-hover-bg);
        flex-direction: row-reverse;
        transition: 0.5s ease-out;

        p {
          margin-left: 0;
        }
      }
    }

  }

`;

export const NewProducts = styled(Layout)`
  width: 100%;
  margin-top: 50px;
  margin-left: 9%;
  background: rgba(0, 82, 123, 1);
  overflow: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-bottom: 20px;
  z-index: 2;
  position: relative;

  @media only screen and ${media.sm}{
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  @media only screen and ${media.xs}{
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .row {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;

    .title {
      background: var(--primary-color);
      padding: 10px 24px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 28px;
      line-height: 36px;
      color: var(--white);
      height: 60px;
      font-family: "robotoMedium" !important;
      margin-top: 30px;

      @media only screen and ${media.xs}{
        font-size: 18px;
      }
      @media only screen and ${media.sm}{
        font-size: 18px;
      }
    }

    .description {
      padding: 10px 24px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 22px;
      line-height: 28px;
      color: var(--white);
      font-family: "robotoRegular" !important;
      width: 70%;
      display: table;

      @media only screen and ${media.sm}{
        font-size: 18px;
      }
      
      @media only screen and ${media.xs}{
        display: none;
      }
    }
  }

  .slider {
    margin-left: 10px;

    @media only screen and ${media.xs}{
      margin-left: 0;
    }
  }

  .show-more {
    width: 210px !important;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    a {
      display: flex;
      width: 210px;
      margin: 0 auto 0 auto;
      align-self: center;
      padding: 10px 24px;
      align-items: center;
      border-radius: 8px;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      text-decoration: none;
      border: 1px solid var(--white);

      b svg {
        fill: var(--white);
        align-self: center;
      }

      P {
        color: var(--white);
        font-family: "robotoMedium" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      &:hover {
        background: var(--item-hover-bg);
        flex-direction: row-reverse;
        transition: 0.5s ease-out;

        p {
          margin-left: 0;
        }
      }
    }
  }
`;
export const Inquiry = styled(Layout)`
  width: 100%;
  flex-shrink: 0;
  display: table;
  background: var(--white);
  position: relative;

  .greenia-1 {
    color: rgba(15, 174, 59, 0.10);
    font-family: "train-one", serif !important;
    font-size: 288px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 50px;
    display: table;
  }

  .greenia-2 {
    color: rgba(15, 174, 59, 0.10);
    font-family: "train-one", serif !important;
    font-size: 288px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 210px;
    position: absolute;
    right: -400px;
    display: table;
  }

  b svg {
    display: table;
  }

  .inquiry {
    width: 80%;
    margin: 300px auto 0 auto;
    background: transparent;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    border-radius: 8px;
    display: flex;

    div {
      margin: 30px 30px 30px 30px;
      align-self: center;
      justify-content: space-between;

      h3 {
        color: var(--dark-blue-color);
        font-family: "robotoMedium" !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }

      p {
        color: var(--dark-blue-color);
        font-family: "robotoRegular" !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-top: 15px;
        margin-bottom: 15px;
      }


      .button {
        text-decoration: none;
        border-radius: 8px;
        background: var(--gray-color);
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
        margin-top: 10px !important;
        margin-bottom: 20px;
        padding: 10px 24px;
        position: absolute;

        a {
          font-family: "robotoMedium" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--dark-blue-color);
        }

        &:hover {
          background: var(--item-hover-bg);
        }
      }
    }
  }

  @media only screen and  ${media.md}{
    .greenia-1 {
      font-size: 200px;
      margin-top: 20px;
      display: table;
    }

    .greenia-2 {
      font-size: 200px;
      margin-top: 140px;
      display: table;
    }

    .inquiry {
      width: 90%;
      margin: 200px auto 0 auto;

      div {
        h3 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and  ${media.sm}{
    b svg {
      width: 150%;
    }

    .greenia-1 {
      display: none;
    }

    .greenia-2 {
      display: none;
    }

    .inquiry {
      width: 90%;
      margin: 20px auto 0 auto;

      div {
        margin-left: 10%;
        margin-top: 0;

        h3 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and  ${media.xs}{
    .greenia-1 {
      display: none;
    }

    .greenia-2 {
      display: none;
    }

    b svg {
      display: none;
    }

    .inquiry {
      width: 90%;
      margin: 20px auto 0 auto;
      padding-bottom: 40px;
      padding-top: 20px;

      div {
        margin-left: 10%;
        margin-top: 0;

        h3 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
`;
