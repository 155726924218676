import styled from "styled-components";
import {Layout} from "antd";

export const Slider = styled(Layout)`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 40px;

  .carousel-container {
    margin: 20px;
  }

  .selected-image {
    width: 500px;
    height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;

    p {
      position: relative;
      bottom: 0;
      margin-top: 520px;
      font-family: "robotoMedium" !important;
      border-left:3px solid var(--black);
      border-radius: 8px;
      padding: 5px;
      font-size: 14px;
    }
  }

  .carousel {
    position: relative;
  }

  .carousel__images {
    max-width: 100%;
  }

  .carousel__image-selected {
    border: 3px solid var(--black) !important;
  }

  .carousel__image {
    margin-right: 10px;
    height: 72px;
    min-width: 72px;
    border: 3px solid var(--gray-color);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
  }

  .carousel__button {
    position: absolute;
    top: 40%;
  }

  .carousel__button-left {
    left: 10px;
  }

  .carousel__button-right {
    right: 10px;
  }

`;