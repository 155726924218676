import {httpApi} from "@app/api/http.api";
import {WishListDomain} from "@app/domains/wish-list.domain";

export interface WishlistStoreResult {
    success: boolean,
    message: string,
    code: number,
    data: { inWishList: boolean }
}

export interface WishlistResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        count: number,
        wishLists: Array<WishListDomain>
    }
}


export const UserAccountWishListStore = (id: number): Promise<WishlistStoreResult> =>
    httpApi.get<WishlistStoreResult>('user-account/wish-list/store/' + id).then(({data}) => data);

export const UserAccountWishList = (page: number): Promise<WishlistResult> =>
    httpApi.get<WishlistResult>('user-account/wish-list?page=' + page).then(({data}) => data);