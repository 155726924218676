import React from "react";
import { Outlet } from "react-router-dom";
import MainHeaderLayout from "@app/components/layouts/home/header/main-navigation.layout";
import * as S from './style.styles';
import FooterLayout from "@app/components/layouts/home/footer/footer.layout";

const HomeLayout: React.FC = () => {
    return (
        <S.LayoutMaster>
            <S.LayoutMain>
                <MainHeaderLayout />
                <Outlet />
                <FooterLayout />
            </S.LayoutMain>
        </S.LayoutMaster>
    )
}

export default HomeLayout;
