import styled from "styled-components";
import {Layout} from "antd";

export const Sidebar = styled(Layout)`
  background: #F2F2F2;
  width: 70%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  overflow-y: auto;

  .authentication {
    background: var(--primary-color);
    padding: 10px 24px;
    display: flex;
    border-radius: 10px;

    span {
      color: var(--white);
      font-family: "robotoRegular" !important;
      text-align: center;
      padding-top: 3px;
      padding-left: 20px;
    }
  }

  ul {
    margin-top: 30px;

    li {
      list-style-type: none;

      .products {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        text-decoration: none;

        span {
          color: var(--black);
          font-family: "robotoRegular" !important;
          line-height: 24px;
        }

        b {
          svg {
            fill: var(--black);
          }
        }
      }

      .sub-menu {
        margin-left: 10px;
        transition: 0.3s;

        span {
          color: var(--gray);
          font-family: "robotoRegular" !important;
          line-height: 24px;
        }

        b {
          svg {
            fill: var(--gray);
          }
        }
      }
    }
  }
`;