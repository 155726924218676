import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PathInterface} from "@app/interfaces/interfaces";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {useLocation} from "react-router-dom";
import {ProductDomain} from "@app/domains/product.domain";
import {ProductBrand} from "@app/api/product.api";
import {ProductCard} from "@app/components/common/product-card/produt-cart.component";
import Pagination from '@mui/material/Pagination';
import {Events, animateScroll as scroll, scrollSpy} from 'react-scroll';
import {BrandDomain} from "@app/domains/brand.domain";

const BrandPage: React.FC = (props) => {
    const {t} = useTranslation()
    const location = useLocation()
    const [brand, setBrand] = useState<BrandDomain>()
    const [products, setProducts] = useState<Array<ProductDomain>>([])
    const [page, setPage] = useState<number>(1)
    const [count, setCount] = useState<number>(1)

    useEffect(() => {
       setBrand(location.state)
        ProductBrand(location.state.slug, 1).then(result => {
            setProducts(result.data.products)
            setCount(Math.round(result.data.count / 18))
            scroll.scrollToTop();
        }).catch(error => {
            console.log(error)
        })
    }, [location.pathname])

    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "/products", title: t("products")},
        {path: "#", title: t('brands')},
        {path: "#", title: brand?.title ?? ''}
    ]

    useEffect(() => {
        Events.scrollEvent.register('begin', (to: any, element: any) => {
            console.log('begin', to, element);
        });
        Events.scrollEvent.register('end', (to: any, element: any) => {
            console.log('end', to, element);
        });
        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    const handlePageClick = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        ProductBrand(location.state.slug, value).then(result => {
            setProducts(result.data.products)
            setCount(Math.round(result.data.count / 18))
            scroll.scrollToTop();
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <S.LayoutMaster>
            <div className={'box'}>
                <RoutePathComponent paths={routePaths}/>

                <div className={'row'}>
                    <div className={'col w-30'}>
                        <img src={brand?.thumbnail} width={200} style={{marginBottom: '20px'}}/>
                        <strong className={'black'}>{brand?.title}</strong>
                        <div className={'black'} dangerouslySetInnerHTML={{__html: brand?.description ?? ""}}></div>
                    </div>
                    <div className={'col w-70'}>
                        <div className={'d-flex'}>
                            {products.map((product, index) => (
                                <ProductCard product={product} key={index}/>
                            ))}
                        </div>

                        <Pagination count={count} color="secondary" page={page} onChange={handlePageClick}/>
                    </div>
                </div>

            </div>

        </S.LayoutMaster>
    )
}

export default BrandPage;