import styled from "styled-components";
import {Layout} from "antd";
import {media} from "@app/assets/styles/themes/constants";
import {BaseButton} from "@app/components/common/BaseButton/BaseButton";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }

    .items {
      width: 100%;
      justify-content: space-between;
      gap: 5px;
      margin: 10px auto 10px auto;
      display: flex;

      .cart-items {
        width: 70%;
        margin-right: 20px;

        .cart-item {
          width: 100%;
          border-radius: 8px;
          border: 1px solid var(--orange);
          margin-top: 20px;
          margin-bottom: 20px;

          .header {
            width: 100%;
            justify-content: space-between;
            background: var(--orange);
            display: flex;
            padding: 10px;

            p {
              color: var(--black);
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              font-family: "robotoMedium" !important;
            }

            b {
              svg {
                cursor: pointer;
                fill: var(--black);
              }
            }
          }

          .body {
            display: flex;
            justify-content: space-between;
            padding: 24px;

            img {
              width: 104px;
              height: 104px;
              margin: 5px;
            }

            .info {
              width: 50%;
              margin-right: 20px;

              p {
                color: var(--black);
                font-family: "robotoMedium" !important;
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
              }

              .row {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                width: 100%;

                p {
                  color: var(--black);
                  font-family: "robotoMedium" !important;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
            }

            .summary {
              width: 30%;
              margin-left: 20px;

              .row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                p {
                  color: var(--black);
                  font-family: "robotoMedium" !important;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }

              .quantity {
                border-radius: 8px;
                border: 1px solid var(--black);
                padding: 10px 24px;
                justify-content: space-between;
                display: flex;
                height: 50px;
                margin-top: 30px;
                margin-bottom: 30px;

                span {
                  color: var(--primary-color);
                  font-size: 22px;
                  font-family: "robotoMedium" !important;
                  margin-right: 10px;
                  margin-left: 10px;
                }

                .pointer {
                  cursor: pointer;
                }
              }
            }

            @media only screen and ${media.sm} {
              display: block;
              .info {
                width: 100%;
              }

              .summary {
                width: 100%;
                margin-left: 0;
                margin-top: 30px;
              }
            }
          }
        }
      }

      .cart-summary {
        width: 30%;

        .summary {
          border-radius: 8px;
          background: var(--white);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30);
          padding: 10px 24px;

          p {
            color: var(--black);
            font-family: "robotoRegular" !important;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
          }

          .row {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            width: 100%;

            p {
              color: var(--black);
              font-family: "robotoRegular" !important;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
      }

      @media only screen and ${media.sm} {
        display: block;

        .cart-items {
          width: 100%;
        }

        .cart-summary {
          width: 100%;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      gap: 5px;
      margin: 10px auto 10px auto;
      flex-wrap: wrap;

      button {
        border: 1px solid var(--black);
        padding: 10px;
        cursor: pointer;
        background: var(--white);
        border-radius: 8px;

        span {
          color: var(--primary-color);
          text-align: center;
          font-family: "robotoRegular" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .link {
        border: 1px solid var(--black);
        padding: 10px;
        cursor: pointer;
        background: var(--white);
        border-radius: 8px;
        text-decoration: none;

        span {
          color: var(--primary-color);
          text-align: center;
          font-family: "robotoRegular" !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer;
  margin-right: 50px;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;

export const SubmitButtonCart = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer; 
  width: 100%;
  margin: 30px auto 30px auto;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;

export const NotFound = styled(Layout)`
  width: 100%;
  margin: 50px auto 0 auto;
  background: var(--white);
  padding: 24px;
  border: none;
  display: table;

  p{
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--black);
    margin-bottom: 40px;
  }

  .link {
    border: 1px solid var(--black);
    padding: 10px;
    cursor: pointer;
    background: var(--white);
    border-radius: 8px;
    text-decoration: none;
    margin-top: 40px;

    span {
      color: var(--primary-color);
      text-align: center;
      font-family: "robotoRegular" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const RequestOrder = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .box-route {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }
  }

  .box {
    width: 80%;
    margin: 30px auto 0 auto;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }

    .row {
      display: flex;
      justify-content: space-between;
      margin: 10px;
      padding: 10px 24px;

      p {
        color: var(--black);
        font-family: "robotoMedium" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 30%;
      }

      .col {
        display: table;
        width: 70%;

        label {
          color: var(--black);
          font-family: "robotoMedium" !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      @media only screen and ${media.sm} {
        display: block;
        
        p{
          width: 100%;
        }
        .col{
          width: 100%;
        }
      }
    }

    .line {
      display: flex;
      justify-content: space-between;
      margin: 20px;
      width: 100%;

      button {
        background: var(--primary-color);
        padding: 10px 24px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        width: 150px;

        span {
          color: var(--white);
          font-family: "robotoRegular" !important;
          font-size: 14px;
          line-height: 24px;
        }
      }

      @media only screen and ${media.sm} {
        display: block;
      }
    }

    .image-row {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 10%;

      img {

      }

      @media only screen and ${media.sm} {
        img{
          width: 100%;
        }
      }
    }
  }

  .header {
    width: 90%;
    padding: 10px 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10%;
    margin-top: 30px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-image: url("../../assets/images/request-order-header.png");

    P {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      margin-left: 30px;
      margin-top: 50px;
      margin-bottom: 10px;
      width: 30%;
    }

    .description {
      color: var(--black);
      font-family: "robotoRegular" !important;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      margin-left: 30px;
      margin-top: 50px;
      margin-bottom: 10px;
      width: 30%;
    }

    @media only screen and ${media.sm} {
      .description{
        display: none;
      }
    }
  }



`;