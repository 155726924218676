import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {useLocation} from "react-router-dom";
import moment from "moment/moment";
import {InquiryProductData, InquiryProductTableColumns} from "@app/interfaces/inquiry.interface";
import {getCurrencyPrice} from "@app/utils/utils";

const UserAccountInquiryDetailPage: React.FC = (props) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)
    const location = useLocation()
    const [products, setProducts] = useState<InquiryProductData[]>([]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        location.state.products.map((product, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setProducts((products) => [
                ...products,
                {
                    id: product.id,
                    index: product.id,
                    title: product.price.product.title,
                    sku: product.price.product.sku,
                    quantity: product.quantity,
                    price: getCurrencyPrice(product.price ? product.price.price : product.uPrice, product.price.country.currency),
                    totalPrice: getCurrencyPrice((product.price ? product.price.price : product.uPrice) * product.quantity, product.price.country.currency),
                    discount: product.discount,
                    description: product.description,
                    action: <></>,
                },
            ]);
        });
    }, [])

    return (
        <S.LayoutMaster>
            <p className={'title'}>{t('inquiry')} : {location.state.code}</p>

            <div className={'box'}>
                <div className={'row'}>
                    <p>{t('code')} : <strong>{location.state.code}</strong></p>
                    <p>{t('created-at')} : <strong>{moment(location.state.createdAt).format('Y-M-D h:i:s')}</strong></p>
                    <p>{t('total-price')} : <strong>{location.state.totalPrice}</strong></p>
                </div>
                <div className={'row'}>
                    <p>{t('status')} : <strong>{location.state.status}</strong></p>
                    <p>{t('shipping-cost')} : <strong>{location.state.shippingCost}</strong></p>
                    <p>{t('vat')} : <strong>{location.state.vat}</strong></p>
                </div>

                <S.Table
                    scroll={{x: 800}}
                    columns={InquiryProductTableColumns(t)}
                    dataSource={products}
                    pagination={false}
                    loading={loading}
                />
            </div>
        </S.LayoutMaster>
    )
}

export default UserAccountInquiryDetailPage;