import React from "react";

export const SearchSvg: React.FC = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.9368 17.6933L27.5768 25.3333L25.5902 27.32L17.9502 19.68C16.5235 20.7067 14.8035 21.3333 12.9235 21.3333C8.13684 21.3333 4.25684 17.4533 4.25684 12.6667C4.25684 7.88 8.13684 4 12.9235 4C17.7102 4 21.5902 7.88 21.5902 12.6667C21.5902 14.5467 20.9635 16.2667 19.9368 17.6933ZM12.9235 6.66667C9.6035 6.66667 6.9235 9.34667 6.9235 12.6667C6.9235 15.9867 9.6035 18.6667 12.9235 18.6667C16.2435 18.6667 18.9235 15.9867 18.9235 12.6667C18.9235 9.34667 16.2435 6.66667 12.9235 6.66667Z"
                 />
        </svg>

    )
}