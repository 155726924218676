import React, {useEffect, useRef, useState} from "react";
import * as S from './styles.style';
import {FileGalleryDomain} from "@app/domains/file-gallery.domain";

const IImageSliderComponent: React.FC<{ images: Array<FileGalleryDomain> }> = ({images}) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [selectedImage, setSelectedImage] = useState<FileGalleryDomain>();
    const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);
    const timeoutRef = React.useRef(null);

    useEffect(() => {
        if (images && images[0]) {
            carouselItemsRef.current = carouselItemsRef.current.slice(
                0,
                images.length
            );

            setSelectedImageIndex(0);
            setSelectedImage(images[0]);
        }
    }, [images]);

    const handleSelectedImageChange = (newIdx: number) => {
        if (images && images.length > 0) {
            setSelectedImage(images[newIdx]);
            setSelectedImageIndex(newIdx);
        }
    };

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
            if (images && images.length > 0) {
                setSelectedImageIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                )
                setSelectedImage(images[selectedImageIndex])
            }

        }, 5000)

        return () => {
            resetTimeout();
        };
    }, [1000]);

    return (
        <S.Slider>
            <div
                className="selected-image"
                style={{backgroundImage: `url(${selectedImage?.file})`}}
            >
                <p>{selectedImage?.title}</p>
            </div>
            <div className="carousel">
                <div className="carousel__images">
                    {images &&
                        images.map((image, idx) => (
                            <div
                                onClick={() => handleSelectedImageChange(idx)}
                                style={{backgroundImage: `url(${image.file})`}}
                                key={image.title}
                                className={`carousel__image ${
                                    selectedImageIndex === idx && "carousel__image-selected"
                                }`}
                                ref={(el) => (carouselItemsRef.current[idx] = el)}
                            />
                        ))}
                </div>
            </div>
        </S.Slider>
    )
}

export default IImageSliderComponent;