import styled from "styled-components";
import {Layout} from "antd";
import {media} from "@app/assets/styles/themes/constants";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl} {
      width: 80%;
    }

    @media only screen and ${media.md} {
      width: 95%;
    }

    @media only screen and ${media.sm} {
      width: 95%;
    }

    @media only screen and ${media.xs} {
      width: 95%;
    }


  }
`;
