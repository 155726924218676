import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {DotMenuScg} from "@app/assets/images/svgs/dot-menu.scg";
import {LikeSvg} from "@app/assets/images/svgs/like.svg";
import {DisLikeScg} from "@app/assets/images/svgs/dis-like.scg";
import {CommentDomain} from "@app/domains/comment.domain";
import moment from "moment/moment";
import {readToken} from "@app/utils/local-storage.service";
import {toast} from "react-toastify";
import {DisLikedComment, LikedComment, ReplyQuestion} from "@app/api/comment.api";
import {QuestionSvg} from "@app/assets/images/svgs/question.svg"
import {ReplySvg} from "@app/assets/images/svgs/reply.svg"
import {TextAreaComponent} from "@app/components/common/text-area/text-area.component";
import validator from "validator";

export const QuestionCard: React.FC<{ question: CommentDomain }> = (props) => {

    const {t} = useTranslation()
    const [likedCount, setLikedCount] = useState<number>(0)
    const [disLikedCount, setDisLikedCount] = useState<number>(0)

    useEffect(() => {
        setLikedCount(props.question.liked)
        setDisLikedCount(props.question.disLiked)
    }, [])

    const handleLiked = () => {
        if (readToken()) {
            LikedComment(props.question.id).then(result => {
                if (result.success) {
                    toast.success(t(result.message))
                    setLikedCount(likedCount + 1)
                } else {
                    toast.error(t(result.message))
                }
            }).catch(error => {
                toast.error(t('failed'))
            })
        } else {
            toast.error(t('login-account'))
        }
    }

    const handleDisLiked = () => {
        if (readToken()) {
            DisLikedComment(props.question.id).then(result => {
                if (result.success) {
                    toast.success(t(result.message))
                    setDisLikedCount(disLikedCount + 1)
                } else {
                    toast.error(t(result.message))
                }
            }).catch(error => {
                toast.error(t('failed'))
            })
        } else {
            toast.error(t('login-account'))
        }
    }

    const [loading, setLoading] = useState<boolean>(false)
    const [replyText, setReplyText] = useState<string>('')
    const [replyTextError, setReplyTextError] = useState<string>('')
    const handleSetReplyText = (event: any) => {
        setReplyTextError('')
        setReplyText(event.target.value)
    }

    const handleSendReply = () => {
        setLoading(true)
        let goOn = true
        setReplyTextError('')
        if (validator.isEmpty(replyText)) {
            setReplyTextError(t('reply-text-required'))
            goOn = true
        }

        if (!goOn)
            setLoading(false)
        else {
            ReplyQuestion(props.question.id, replyText).then(result => {
                if (result.success) {
                    toast.success(t(result.message))
                    setReplyText('')
                } else {
                    toast.error(t(result.message))
                }
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                toast.error(t('sending-failed'))
            })
        }
    }

    return (
        <S.Comment>
            <div className={'header'}>
                <div className={'author'}>
                    <img src={props.question.author?.avatar}/>

                    <div>
                        <p>{props.question.author.firstName} {props.question.author.lastName}</p>
                        <p>{moment(props.question.createdAt).format('Y-M-D h:i:s')}</p>
                    </div>
                </div>

                <button>
                    <DotMenuScg/>
                </button>
            </div>

            <div className={'body'}>
                <div className={'question'}>
                    <b className={'black'}>
                        <QuestionSvg/>
                    </b>
                    <p>
                        {props.question.text}
                    </p>
                </div>


                <div className={'r'}>
                    {props.question.replies.filter(item => item.status === 'confirmed').map((reply, index) => (
                        <div className={'reply'} key={index}>
                            <b className={'black'}>
                                <ReplySvg/>
                            </b>
                            <p>
                                {reply.text}
                                <br/>
                                <span>{reply.author.firstName} {reply.author.lastName}</span>
                            </p>
                        </div>
                    ))}
                </div>

                <div className={'reply-box'}>
                    <TextAreaComponent error={replyTextError} value={replyText} onChange={handleSetReplyText} rows={3}
                                       placeholder={t('reply')}/>
                    <S.SubmitButton loading={loading} onClick={handleSendReply}>{t('send')}</S.SubmitButton>
                </div>
            </div>

            <div className={'footer'}>
                <div className={'like'} onClick={handleDisLiked}>
                    <b>
                        <LikeSvg/>
                    </b>
                    <p>{disLikedCount}</p>
                </div>

                <div className={'dis-like'} onClick={handleLiked}>
                    <b>
                        <DisLikeScg/>
                    </b>
                    <p>{likedCount}</p>
                </div>
            </div>
        </S.Comment>
    )
}