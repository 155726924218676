import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as S from '../styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import RequestOrderImage from "@app/assets/images/requestorder.png"
import {useLocation} from "react-router-dom";

const RequestOrderDetailPage: React.FC = (props) => {
    const {t} = useTranslation()
    const location = useLocation();
    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "/cart", title: 'Cart'},
        {path: "#", title: 'Request for order'},
        {path: "#", title: 'Request detail'},
    ]
    useEffect(() => {
        console.log(location)
    }, [])


    return (
        <S.LayoutMaster>
            <S.RequestOrder>
                <div className={'box-route'}>
                    <RoutePathComponent paths={routePaths}/>
                </div>
                <div className={'header'}>
                    <p>{t('your-request-inquiry')}</p>
                    <p className={'description'}>{t('request-description')}</p>
                </div>
                <div className={'box'}>

                    <div className={'image-row'}>
                        <img src={RequestOrderImage} className={'image'}/>
                    </div>
                </div>
            </S.RequestOrder>
        </S.LayoutMaster>
    )
}

export default RequestOrderDetailPage;