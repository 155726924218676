import { httpApi } from "@app/api/http.api";
import { ProductDomain } from "@app/domains/product.domain";
import { BrandDomain } from "@app/domains/brand.domain";

export interface ProductResult {
    success: boolean,
    message: string,
    code: number,
    data: ProductDomain
}

export interface ProductResultArray {
    success: boolean,
    message: string,
    code: number,
    data: {
        products: Array<ProductDomain>,
        count: number
    }
}

export interface ProductResultBrand {
    success: boolean,
    message: string,
    code: number,
    data: {
        products: Array<ProductDomain>,
        brand: BrandDomain,
        count: number
    }
}

export const SingleProductDetail = (slug: string): Promise<ProductResult> =>
    httpApi.get<ProductResult>('products/detail/' + slug).then(({ data }) => data);


export const ProductCategories = (slug: string, page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/categories/' + slug + '?page=' + page).then(({ data }) => data);

export const ProductBrand = (slug: string, page: number): Promise<ProductResultBrand> =>
    httpApi.get<ProductResultBrand>('products/brand/' + slug + '?page=' + page).then(({ data }) => data);

export const AdvanceSearch = (page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/advance-search?page=' + page).then(({ data }) => data);

export const HotProducts = (page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/hot?page=' + page).then(({ data }) => data);

export const NewProducts = (page: number): Promise<ProductResultArray> =>
    httpApi.get<ProductResultArray>('products/new?page=' + page).then(({ data }) => data);

export const SearchProduct = (title: string, multiSearch: boolean, page: number): Promise<ProductResultArray> =>
    httpApi.post<ProductResultArray>('products/search?page=' + page, { title: title, multiSearch: multiSearch }).then(({ data }) => data);

