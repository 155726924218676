import React from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './styles.style';
import SidebarLayout from "@app/components/layouts/user-account/sidebar/sidebar.layout";

const UserAccountLayout: React.FC = () => {
  return (
    <S.Wrapper>
        <div className={'flex'}>
            <SidebarLayout/>
            <div className={'main'}>
                <Outlet />
            </div>
        </div>
    </S.Wrapper>
  );
};

export default UserAccountLayout;
