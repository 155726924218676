import React from "react";

export const LogoutSvg: React.FC = () => {
    return (
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.75 8.75L19.25 12L15.75 15.25"/>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 12H10.75"/>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H15.25"/>
        </svg>

    )
}