import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {DotMenuScg} from "@app/assets/images/svgs/dot-menu.scg";
import {LikeSvg} from "@app/assets/images/svgs/like.svg";
import {DisLikeScg} from "@app/assets/images/svgs/dis-like.scg";
import {CommentDomain} from "@app/domains/comment.domain";
import moment from "moment/moment";
import {readToken} from "@app/utils/local-storage.service";
import {toast} from "react-toastify";
import {DisLikedComment, LikedComment} from "@app/api/comment.api";

export const CommentCard: React.FC<{ comment: CommentDomain }> = (props) => {

    const {t} = useTranslation()
    const [likedCount, setLikedCount] = useState<number>(0)
    const [disLikedCount, setDisLikedCount] = useState<number>(0)

    useEffect(()=>{
        setLikedCount(props.comment.liked)
        setDisLikedCount(props.comment.disLiked)
    }, [])

    const handleLiked = () => {
        if (readToken()) {
            LikedComment(props.comment.id).then(result => {
                if (result.success) {
                    toast.success(t(result.message))
                    setLikedCount(likedCount + 1)
                } else {
                    toast.error(t(result.message))
                }
            }).catch(error => {
                toast.error(t('failed'))
            })
        } else {
            toast.error(t('login-account'))
        }
    }

    const handleDisLiked = () => {
        if (readToken()) {
            DisLikedComment(props.comment.id).then(result => {
                if (result.success) {
                    toast.success(t(result.message))
                    setDisLikedCount(disLikedCount + 1)
                } else {
                    toast.error(t(result.message))
                }
            }).catch(error => {
                toast.error(t('failed'))
            })
        } else {
            toast.error(t('login-account'))
        }
    }

    return (
        <S.Comment>
            <div className={'header'}>
                <div className={'author'}>
                    <img src={props.comment.author?.avatar}/>

                    <div>
                        <p>{props.comment.author.firstName} {props.comment.author.lastName}</p>
                        <p>{moment(props.comment.createdAt).format('Y-M-D h:i:s')}</p>
                    </div>
                </div>

                <button>
                    <DotMenuScg/>
                </button>
            </div>

            <div className={'body'}>
                <p>{props.comment.text}</p>
            </div>

            <div className={'footer'}>
                <div className={'like'} onClick={handleDisLiked}>
                    <b>
                        <LikeSvg/>
                    </b>
                    <p>{disLikedCount}</p>
                </div>

                <div className={'dis-like'} onClick={handleLiked}>
                    <b>
                        <DisLikeScg/>
                    </b>
                    <p>{likedCount}</p>
                </div>
            </div>
        </S.Comment>
    )
}