import React from "react";

export const PriceAlertSvg: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_561_38189)">
                <path
                    d="M8.00022 1.11108C11.9202 1.11108 15.1113 3.9022 15.1113 7.33331C15.1113 10.7644 11.9202 13.5555 8.00022 13.5555C7.26682 13.5547 6.53702 13.453 5.83133 13.2533L3.37355 14.8178C3.30645 14.8608 3.22901 14.885 3.14935 14.8878C3.06968 14.8907 2.99071 14.872 2.92073 14.8339C2.85074 14.7957 2.79231 14.7394 2.75156 14.6709C2.71081 14.6024 2.68924 14.5241 2.68911 14.4444V11.4533C2.133 10.9198 1.68923 10.2805 1.38392 9.57294C1.07861 8.86538 0.917926 8.1039 0.911328 7.33331C0.889107 3.9022 4.08022 1.11108 8.00022 1.11108ZM3.42688 10.9378C3.47157 10.9792 3.50725 11.0294 3.53172 11.0852C3.55618 11.141 3.56891 11.2013 3.56911 11.2622V13.6355L5.51577 12.3955C5.57007 12.3612 5.63117 12.3391 5.69485 12.3306C5.75854 12.3222 5.82329 12.3276 5.88466 12.3466C6.56967 12.5594 7.28293 12.6673 8.00022 12.6666C11.4313 12.6666 14.2224 10.2755 14.2224 7.33331C14.2224 4.39108 11.4313 1.99997 8.00022 1.99997C4.56911 1.99997 1.778 4.39108 1.778 7.33331C1.78813 8.01556 1.93993 8.68826 2.22378 9.30875C2.50763 9.92924 2.91732 10.484 3.42688 10.9378Z"
                />
                <path
                    d="M8.00078 9.16891C7.88291 9.16891 7.76986 9.12208 7.68651 9.03873C7.60316 8.95538 7.55633 8.84234 7.55633 8.72446V3.76891C7.55633 3.65103 7.60316 3.53799 7.68651 3.45464C7.76986 3.37129 7.88291 3.32446 8.00078 3.32446C8.11865 3.32446 8.2317 3.37129 8.31505 3.45464C8.3984 3.53799 8.44522 3.65103 8.44522 3.76891V8.71557C8.44641 8.77468 8.43578 8.83343 8.41398 8.88839C8.39217 8.94334 8.35962 8.99339 8.31823 9.03561C8.27685 9.07782 8.22745 9.11136 8.17294 9.13425C8.11843 9.15714 8.0599 9.16892 8.00078 9.16891Z"
                />
                <path
                    d="M8.00069 11.2756C7.67422 11.2756 7.40957 11.011 7.40957 10.6845C7.40957 10.358 7.67422 10.0934 8.00069 10.0934C8.32715 10.0934 8.5918 10.358 8.5918 10.6845C8.5918 11.011 8.32715 11.2756 8.00069 11.2756Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_561_38189">
                    <rect width="16" height="16" transform="matrix(-1 0 0 1 16 0)"/>
                </clipPath>
            </defs>
        </svg>

    )
}