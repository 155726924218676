import * as S from "./footer.style"
import { Link } from "react-router-dom";
import { LightSvg } from "@app/assets/images/svgs/light.svg";

import React from "react";
import { useTranslation } from "react-i18next";
import { NewsLetterSvg } from "@app/assets/images/svgs/news-letter.svg";
import { ReactComponent as LogoSvg } from "@app/assets/images/svgs/logo-1.svg"
import { TellSVg } from "@app/assets/images/svgs/tell.svg";
import { EmailSvg } from "@app/assets/images/svgs/email.svg";
import { FacebookSsg } from "@app/assets/images/svgs/facebook.ssg";
import { InstagramSvg } from "@app/assets/images/svgs/instagram.svg";

const FooterLayout: React.FC = () => {

    const { t } = useTranslation()

    return (
        <S.FooterLayout>
            <S.NeedHelp>
                <div className={'help'}>
                    <div className={'title'}>
                        <div className={'green-line'} />
                        <span>{t('need-help')}</span>
                        <div className={'green-line radius-left'} />
                    </div>
                    <div className={'body'}>
                        <Link to={'/contact-us'} className={'box'}>
                            <a>
                                <h3>
                                    <b><LightSvg /></b>
                                    {t('contact-sales-support')}
                                </h3>
                                <p>{t('contact-sales-support-description')}</p>
                            </a>
                        </Link>
                        <Link to={'/'} className={'box'}>
                            <a>
                                <h3>
                                    <b><LightSvg /></b>
                                    {t('start-here')}
                                </h3>
                                <p>{t('start-here-description')}</p>
                            </a>
                        </Link>
                        <Link to={'/'} className={'box'}>
                            <a>
                                <h3>
                                    <b><LightSvg /></b>
                                    {t('how-to-buy')}
                                </h3>
                                <p>{t('how-to-buy-description')}</p>
                            </a>
                        </Link>
                        <Link to={'/how-to-buy'} className={'box'}>
                            <a>
                                <h3>
                                    <b><LightSvg /></b>
                                    {t('how-to-return')}
                                </h3>
                                <p>{t('how-to-return-description')}</p>
                            </a>
                        </Link>
                    </div>
                </div>
                <div className={'newsletter'}>
                    <b>
                        <NewsLetterSvg />
                    </b>
                    <div className={'background'}>
                        <div>
                            <p>{t('subscribe-title')}</p>
                            <p>{t('subscribe-description')}</p>
                        </div>

                        <input type={"email"} placeholder={t('subscribe-placeholder')} />
                        <button type={"button"}>{t('subscribe')}</button>
                    </div>
                </div>
            </S.NeedHelp>

            <S.Footer>
                <Link className="logo-container" to={'/'}>
                    <LogoSvg className={'logo'} />
                </Link>
                <div className={'row'}>
                    <div className={'col'}>
                        <ul>
                            <li>
                                <a>
                                    <b>
                                        <TellSVg />
                                    </b>
                                    +49 174 487 75 66
                                </a>
                            </li>
                            <li>
                                <a>
                                    <b>
                                        <EmailSvg />
                                    </b>
                                    Support@greenia.com
                                </a>
                            </li>
                            <li className={'social'}>
                                <p>{t('follow-us')}</p>
                                <a className={'icon'}>
                                    <b>
                                        <FacebookSsg />
                                    </b>
                                </a>
                                <a className={'icon'}>
                                    <b>
                                        <InstagramSvg />
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={'col'}>
                        <p className={'title'}>{t('company')}</p>
                        <ul>
                            <li>
                                <Link to={'/about-us'}>
                                    <a>{t('about-us')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/contact-us'}>
                                    <a>{t('contact-us')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/privacy-policy'}>
                                    <a>{t('privacy-policy')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/corporate-group'}>
                                    <a>{t('corporate-group')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/terms-condition'}>
                                    <a>{t('terms-condition')}</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={'col'}>
                        <p className={'title'}>{t('customer-service')}</p>
                        <ul>
                            <li>
                                <Link to={'/user-account'}>
                                    <a>{t('my-account')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/how-to-pay'}>
                                    <a>{t('how-to-pay')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/how-to-order'}>
                                    <a>{t('how-to-order')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/how-to-return'}>
                                    <a>{t('how-to-return')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/customer-feedback'}>
                                    <a>{t('customer-feedback')}</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={'col'}>
                        <p className={'title'}>{t('resources')}</p>
                        <ul>
                            <li>
                                <Link to={'/blog'}>
                                    <a>{t('blog')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/news'}>
                                    <a>{t('news')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/sitemap.xml'}>
                                    <a>{t('sitemap')}</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={'col'}>
                        <p className={'title'}>{t('sell-your-product-on-greenia')}</p>
                        <div className={'form'}>
                            <input type={"email"} placeholder={'email-address'} />
                            <button type={"button"}>{t('submit')}</button>
                        </div>
                    </div>
                </div>

                <div className={'divider'} />
                <p className={'copy-right'}>{t('copy-right')}</p>
            </S.Footer>

        </S.FooterLayout>
    )
}

export default FooterLayout;
