import React from "react";

export const FavoriteSvg: React.FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4911 13.142L13.8341 18.8C13.4591 19.175 12.9505 19.3856 12.4201 19.3856C11.8898 19.3856 11.3812 19.175 11.0061 18.8L5.34912 13.143C4.88149 12.6795 4.51003 12.1281 4.25605 11.5206C4.00208 10.9131 3.87059 10.2614 3.86915 9.60299C3.86771 8.94454 3.99634 8.29229 4.24765 7.68368C4.49896 7.07508 4.86801 6.5221 5.33361 6.05651C5.7992 5.59091 6.35218 5.22186 6.96078 4.97055C7.56939 4.71924 8.22164 4.59061 8.88009 4.59205C9.53854 4.59349 10.1902 4.72498 10.7977 4.97895C11.4052 5.23293 11.9566 5.60439 12.4201 6.07202C13.3617 5.1539 14.6271 4.64369 15.9422 4.65193C17.2572 4.66017 18.5161 5.18619 19.4461 6.11605C20.3761 7.0459 20.9023 8.30472 20.9107 9.6198C20.9191 10.9349 20.4091 12.2003 19.4911 13.142Z"
                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}