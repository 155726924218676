export const WishlistTableColumns = (t: any) => [
    {
        title: '#',
        dataIndex: 'thumbnail',
        showSorterTooltip: true,
    },
    {
        title: t('title'),
        dataIndex: 'title',
        showSorterTooltip: true,
        editable: true,
    },
    {
        title: t('sku'),
        dataIndex: 'sku',
        showSorterTooltip: true,
        editable: true,
    },
    {
        title: t('price'),
        dataIndex: 'price',
        showSorterTooltip: true,
        editable: true,
    },
    {
        title: '',
        dataIndex: 'action',
        width: '15%',
    },
];

export interface WishlistData {
    id: number;
    thumbnail: JSX.Element;
    title: string;
    sku: string;
    price: string;
    action: JSX.Element;
}