import styled from "styled-components";
import {Layout} from "antd";
import {FONT_SIZE} from "@app/assets/styles/themes/constants";
import { Table as AntdTable } from 'antd';

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .title {
    color: var(--white);
    font-family: "robotoRegular" !important;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: var(--primary-color);
    margin: 10px 30px 10px 30px
  }
  
  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 10px;

      p {
        font-family: "robotoRegular" !important;
        font-size: 14px;
        line-height: 24px;
        padding: 5px;
        color: var(--black);
      }

      strong {
        color: var(--black);
        margin-left: 10px;
        font-family: "robotoMedium" !important;
      }
    }
  }
`;

export const Table = styled(AntdTable)`
  width: 90%;
  margin: 30px auto 0 auto;

  table {
    width: 90%;
    margin: 30px auto 0 auto;
  }

  & thead .ant-table-cell {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    text-align: left;
    padding: 10px 24px;
    border-bottom: 1px solid var(--primary-color);

    & .anticon {
      color: var(--primary-color);
    }
  }

  & tbody .ant-table-cell {
    color: var(--black);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    padding: 10px 24px;
  }

  & tbody .ant-table-cell a {
    color: var(--white);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
    padding: 10px 24px;
    background: var(--red);
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
    
    &:hover{
      background: var(--white);
      color: var(--red);
    }
  }

  & tbody tr:hover {
    background: var(--light-gray);
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 0.1875rem;
    margin-top: 0;
  }

  // Override default antd selector
  &
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: var(--primary-color);
  }


  .ant-pagination {
    box-sizing: border-box;
    color: var(--text-main-color);
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum';
    margin-top: 30px;
  }

  .ant-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' '
  }

  .ant-pagination-item,.ant-pagination-total-text {
    display: inline-block;
    margin-right: 8px;
    line-height: 48px;
    vertical-align: middle
  }

  .ant-pagination-total-text {
    height: 50px
  }

  .ant-pagination-item {
    min-width: 50px;
    height: 50px;
    text-align: center;
    list-style: none;
    background-color: var(--background-color);
    border: 1px solid var(--border-base-color);
    border-radius: 7px;
    outline: 0;
    cursor: pointer;
    user-select: none;
    color: var(--black)
  }

  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: var(--black)
  }

  .ant-pagination-item-active a,.ant-pagination-item:focus-visible a{
    color: var(--white)
  }

  .ant-pagination-item:hover a {
    color: var(--primary-color)
  }

  .ant-pagination-item:focus-visible,.ant-pagination-item:hover {
    transition: all .3s;
    border-color: var(--ant-primary-color)
  }

  .ant-pagination-item a:hover {
    text-decoration: none
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white);
  }

  .ant-pagination-item-active:focus-visible,.ant-pagination-item-active:hover,.ant-pagination-next:hover button,.ant-pagination-prev:hover button {
    border-color: var(--ant-primary-5)
  }

  .ant-pagination-item-active:focus-visible a,.ant-pagination-item-active:hover a {
    color: var(--white)
  }

  .ant-pagination-jump-next,.ant-pagination-jump-prev {
    outline: 0
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--ant-primary-color);
    font-size: 14px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all .2s
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(0,0,0,.25);
    font-family: Arial,Helvetica,sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: .13em;
    opacity: 1;
    transition: all .2s
  }

  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 1
  }

  .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 0
  }

  .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon {
    opacity: 1
  }

  .ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis {
    opacity: 0
  }

  .ant-pagination-jump-next,.ant-pagination-jump-prev,.ant-pagination-prev {
    margin-right: 8px
  }

  .ant-pagination-jump-next,.ant-pagination-jump-prev,.ant-pagination-next,.ant-pagination-prev {
    display: inline-block;
    min-width: 50px;
    height: 50px;
    color: var(--text-main-color);
    font-family: Montserrat,sans-serif;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 7px;
    cursor: pointer;
    transition: all .3s
  }

  .ant-pagination-next,.ant-pagination-prev {
    font-family: Arial,Helvetica,sans-serif;
    outline: 0
  }

  .ant-pagination-next button,.ant-pagination-prev button {
    color: var(--text-main-color);
    cursor: pointer;
    user-select: none
  }

  .ant-pagination-next .ant-pagination-item-link,.ant-pagination-prev .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: var(--background-color);
    border: 1px solid var(--border-base-color);
    border-radius: 7px;
    outline: 0;
    transition: all .3s;
    color: var(--black);
  }

  .ant-pagination-next:focus-visible .ant-pagination-item-link,.ant-pagination-next:hover .ant-pagination-item-link,.ant-pagination-prev:focus-visible .ant-pagination-item-link,.ant-pagination-prev:hover .ant-pagination-item-link {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color)
  }

  .ant-pagination-disabled .ant-pagination-item-link,.ant-pagination-disabled:focus-visible .ant-pagination-item-link,.ant-pagination-disabled:hover .ant-pagination-item-link {
    border-color: var(--border-base-color);
    color: rgba(0,0,0,.25);
    cursor: not-allowed
  }

  .ant-pagination-disabled,.ant-pagination-disabled:hover {
    cursor: not-allowed
  }

  .ant-pagination-disabled:focus-visible {
    cursor: not-allowed
  }

  .ant-pagination-slash {
    margin: 0 10px 0 5px
  }

  .ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle
  }

  @media all and (-ms-high-contrast:none) {
    .ant-pagination-options,.ant-pagination-options ::-ms-backdrop {
      vertical-align: top
    }
  }

  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto
  }

  .ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 50px;
    margin-left: 8px;
    line-height: 50px;
    vertical-align: top
  }

  .ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    min-width: 0;
    padding: 11.4px 11px;
    color: var(--text-main-color);
    font-size: 16px;
    line-height: 1.5715;
    background-color: var(--background-color);
    background-image: none;
    border: 1px solid var(--border-base-color);
    border-radius: 7px;
    transition: all .3s;
    width: 50px;
    height: 50px;
    margin: 0 8px
  }

  .ant-pagination-options-quick-jumper input::placeholder {
    color: var(--input-placeholder-color);
    user-select: none
  }

  .ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis
  }

  .ant-pagination-options-quick-jumper input:hover {
    border-color: var(--ant-primary-5);
    border-right-width: 1px
  }

  .ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
    border-right-width: 0;
    border-left-width: 1px!important
  }

  .ant-pagination-options-quick-jumper input-focused,.ant-pagination-options-quick-jumper input:focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px var(--ant-primary-color-outline);
    border-right-width: 1px;
    outline: 0
  }

  .ant-input-rtl .ant-pagination-options-quick-jumper input-focused,.ant-input-rtl .ant-pagination-options-quick-jumper input:focus {
    border-right-width: 0;
    border-left-width: 1px!important
  }

  .ant-pagination-options-quick-jumper input-disabled:hover,.ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: var(--border-base-color);
    border-right-width: 1px
  }

  .ant-pagination-options-quick-jumper input-disabled {
    color: var(--disabled-color);
    background-color: var(--disabled-bg-color);
    border-color: var(--border-base-color);
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1
  }

  .ant-pagination-options-quick-jumper input[disabled] {
    color: var(--disabled-color);
    background-color: var(--disabled-bg-color);
    border-color: var(--border-base-color);
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1
  }

  .ant-pagination-options-quick-jumper input-borderless,.ant-pagination-options-quick-jumper input-borderless-disabled,.ant-pagination-options-quick-jumper input-borderless-focused,.ant-pagination-options-quick-jumper input-borderless:focus,.ant-pagination-options-quick-jumper input-borderless:hover,.ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none
  }

  textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 50px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all .3s,height 0s
  }

  .ant-pagination-options-quick-jumper input-lg {
    padding: 16.9px 11px;
    font-size: 18px
  }

  .ant-pagination-options-quick-jumper input-sm {
    padding: 2.4px 7px
  }

  .ant-pagination-simple .ant-pagination-next,.ant-pagination-simple .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    vertical-align: top
  }

  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0
  }

  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after,.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px
  }

  .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px
  }

  .ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: var(--background-color);
    border: 1px solid var(--border-base-color);
    border-radius: 7px;
    outline: 0;
    transition: border-color .3s
  }

  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: var(--ant-primary-color)
  }

  .ant-pagination-simple .ant-pagination-simple-pager input:focus {
    border-color: var(--ant-primary-color-hover);
    box-shadow: 0 0 0 2px var(--ant-primary-color-outline)
  }

  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(0,0,0,.25);
    background: var(--disabled-bg-color);
    border-color: var(--border-base-color);
    cursor: not-allowed
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active),.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link,.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link {
    background: 0 0;
    border-color: transparent
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager,.ant-pagination.ant-pagination-mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-next,.ant-pagination.ant-pagination-mini .ant-pagination-prev {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after,.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-jump-next,.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev {
    height: 24px;
    margin-right: 0;
    line-height: 24px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-left: 2px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
    top: 0
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
    padding: 2.4px 7px;
    width: 44px;
    height: 32px
  }

  .ant-pagination.ant-pagination-disabled {
    cursor: not-allowed
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: var(--disabled-bg-color);
    border-color: var(--border-base-color);
    cursor: not-allowed
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(0,0,0,.25);
    background: 0 0;
    border: none;
    cursor: not-allowed
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #e6e6e6
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: var(--disabled-color)
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(0,0,0,.25);
    background: var(--disabled-bg-color);
    border-color: var(--border-base-color);
    cursor: not-allowed
  }

  .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: 0 0
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
    opacity: 0
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
    opacity: 1
  }

  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(0,0,0,.25)
  }

  @media only screen and (max-width: 992px) {
    .ant-pagination-item-after-jump-prev,.ant-pagination-item-before-jump-next {
      display:none
    }
  }

  @media only screen and (max-width: 568px) {
    .ant-pagination-options {
      display:none
    }
  }

  .ant-pagination-rtl .ant-pagination-item,.ant-pagination-rtl .ant-pagination-jump-next,.ant-pagination-rtl .ant-pagination-jump-prev,.ant-pagination-rtl .ant-pagination-prev,.ant-pagination-rtl .ant-pagination-total-text {
    margin-right: 0;
    margin-left: 8px
  }

  .ant-pagination-rtl .ant-pagination-slash {
    margin: 0 5px 0 10px
  }

  .ant-pagination-rtl .ant-pagination-options {
    margin-right: 16px;
    margin-left: 0
  }

  .ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
    margin-left: 8px
  }

  .ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
    margin-left: 0
  }

  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager,.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
    margin-right: 0;
    margin-left: 8px
  }

  .ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
    margin-right: 2px;
    margin-left: 0
  }

`;

export const TablesWrapper = styled.div`
  width: 100%;
  margin-top: 1.875rem;
`;
