import * as S from './product-card.style';
import { Link } from "react-router-dom";
import { CompareSvg } from "@app/assets/images/svgs/compare.svg";
import { WishListSvg } from "@app/assets/images/svgs/wishlist.svg";
import { QuickViewSvg } from "@app/assets/images/svgs/quick0view.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductDomain } from "@app/domains/product.domain";
import { getCurrencyPrice } from "@app/utils/utils";


export const ProductCard: React.FC<{ product: ProductDomain, key: number }> = ({ product }) => {

    const { t } = useTranslation()

    const { brand, regularPrice } = product;
    // console.log('brandbrand', brand)
    return (
        <S.Product>
            <figure>
                <Link to={`/brands/${brand.slug}`} className={'brand'} state={brand}>
                    <a>
                        <span>{brand.title}</span>
                    </a>
                </Link>
                <Link to={`/products/${product.slug}/`} state={{ product: product }}>
                    <a>
                        <img src={product.thumbnail} alt={product.title} />
                    </a>
                </Link>
                <div className={'product-actions'}>
                    <Link to={'/'}>
                        <a>
                            <b>
                                <CompareSvg />
                            </b>
                            <span>{t('compare')}</span>
                        </a>
                    </Link>
                    <Link to={'/'}>
                        <a>
                            <b>
                                <WishListSvg />
                            </b>
                            <span>{t('wishlist')}</span>
                        </a>
                    </Link>
                    <Link to={'/'}>
                        <a>
                            <b>
                                <QuickViewSvg />
                            </b>
                            <span>{t('quick-view')}</span>
                        </a>
                    </Link>
                </div>
            </figure>
            <div className={'body'}>
                <Link to={`/products/${product.slug}`} state={{ product: product }} className={'product-title'}>
                    <a>
                        <h1>{product.title}</h1>
                    </a>
                </Link>
                <p className={'product-description'} dangerouslySetInnerHTML={{ __html: product.shortDescription }}></p>

                <h5 className={'product-price'}>{getCurrencyPrice(regularPrice.price, regularPrice.country.currency)} (Budget)</h5>
                <h5 className={'product-status yellow'}>{t(regularPrice.stockStatus)}</h5>
            </div>
            <div className={'footer'}>
                <div className={'quantity'}>
                    <span className={'pointer'}>-</span>
                    <span>1</span>
                    <span className={'pointer'}>+</span>
                </div>

                <div className={'add-to-card'}>
                    <button type={'button'}>{t('add-to-card')}</button>
                </div>
            </div>
        </S.Product>
    )
}