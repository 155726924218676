import React from "react";

export const LightSvg: React.FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0625 19.1235H14.1875V15.2505H10.0625V19.1235ZM12.125 21.1245C13.5455 21.1245 13.9872 20.3542 14.1252 19.875H10.1247C10.2627 20.355 10.7045 21.1245 12.125 21.1245ZM12.125 2.1247C10.7326 2.1247 9.39726 2.67783 8.41269 3.66239C7.42812 4.64696 6.875 5.98232 6.875 7.3747C6.875 10.2697 9.83375 11.0775 10.0422 14.4997H10.9947C10.9887 14.4802 10.9775 14.4622 10.9737 14.4397L9.47375 6.19195C9.45964 6.10379 9.47731 6.01349 9.52362 5.93715C9.56993 5.86082 9.64186 5.80343 9.72657 5.77522C9.81128 5.74702 9.90326 5.74983 9.98608 5.78316C10.0689 5.8165 10.1372 5.87818 10.1787 5.9572L10.5935 6.7867L11.0082 5.95645C11.0395 5.89444 11.0874 5.84233 11.1465 5.80592C11.2056 5.76951 11.2737 5.75023 11.3431 5.75023C11.4126 5.75023 11.4806 5.76951 11.5398 5.80592C11.5989 5.84233 11.6467 5.89444 11.678 5.95645L12.0927 6.78595L12.5075 5.9557C12.5384 5.89324 12.5861 5.84065 12.6452 5.80387C12.7044 5.7671 12.7727 5.74762 12.8424 5.74762C12.912 5.74762 12.9803 5.7671 13.0395 5.80387C13.0987 5.84065 13.1464 5.89324 13.1772 5.9557L13.5928 6.7852L14.0075 5.95495C14.0486 5.8756 14.1167 5.81355 14.1996 5.77997C14.2824 5.7464 14.3745 5.7435 14.4593 5.77182C14.544 5.80014 14.6159 5.8578 14.6619 5.93442C14.7079 6.01103 14.7251 6.10157 14.7102 6.1897L13.2102 14.4382C13.2072 14.4607 13.1953 14.478 13.1892 14.4967H14.2055C14.414 11.0752 17.3735 10.2667 17.3735 7.3717C17.3735 4.4737 15.023 2.1217 12.1235 2.1217L12.125 2.1247ZM13.5935 7.99945C13.524 7.99973 13.4559 7.98055 13.3968 7.9441C13.3377 7.90765 13.2899 7.85538 13.259 7.7932L12.8435 6.96445L12.4287 7.79395C12.3972 7.85591 12.3492 7.90795 12.2899 7.94432C12.2307 7.98069 12.1625 7.99997 12.093 8.00005C12.0235 8.00013 11.9553 7.98099 11.8959 7.94476C11.8366 7.90852 11.7884 7.85659 11.7567 7.7947L11.342 6.96595L10.9272 7.79545C10.8964 7.85777 10.8487 7.9102 10.7896 7.94678C10.7305 7.98337 10.6623 8.00266 10.5927 8.00245H10.565L11.6562 14.0025H12.5315L13.622 8.00245H13.5935V7.99945Z"
               />
        </svg>
    )
}