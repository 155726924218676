import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import {WishlistData, WishlistTableColumns} from "@app/interfaces/wish-list.interface";
import {UserAccountWishList, UserAccountWishListStore} from "@app/api/user-account/wish-list.api";
import {toast} from "react-toastify";

const WishListPage: React.FC = (props) => {
    const {t} = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [count, setCount] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(true)
    const [wishLists, setWishLists] = useState<WishlistData[]>([]);

    useEffect(() => {
        loadData(1)
    }, [])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const loadData = (page) => {
        UserAccountWishList(page).then(result => {
            console.log(result)
            if (result.success) {
                result.data.wishLists.map((wishList, index) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setWishLists((wishLists) => {
                        return [
                            ...wishLists,
                            {
                                id: wishList.product.id,
                                thumbnail: (<img src={wishList.product.thumbnail} width={60}/>),
                                title: wishList.product.title,
                                sku: wishList.product.sku,
                                price: wishList.product.regularPrice.price,
                                action: (<a className={'remove'}
                                            onClick={() => remove(wishList.product.id)}>{t('remove')}</a>),
                            },
                        ];
                    });
                });

                setCount(result.data.count)
            }
            setLoading(false)
        }).catch(error => {
            console.log(error, "error")
            setLoading(false)
        })
    }

    const remove = (id: number) => {
        setLoading(true)
        UserAccountWishListStore(id).then(result => {
            if (result.success) {
                toast.success(t(result.message))

                loadData(1)
            } else {
                alert(result.message)
                toast.error(t('add-to-wish-list-failed'))
            }
            setLoading(false)
        }).catch(error => {
            toast.error(error)
            setLoading(false)
        })
    }

    const onChange = (page: any) => {
        setLoading(true)
        setPage(page.current);
        loadData(page.current)
    };

    return (
        <S.LayoutMaster>
            <p className={'title'}>{t('wish-list')}</p>

            <S.TablesWrapper>
                <S.Table scroll={{x: 800}} columns={WishlistTableColumns(t)} dataSource={wishLists} loading={loading}
                         pagination={{pageSize: 20, current: page, total: count}}
                         onChange={(page: any) => onChange(page)}/>
            </S.TablesWrapper>
        </S.LayoutMaster>
    )
}

export default WishListPage;