import React from "react";

export const QuickViewSvg: React.FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99966 4.16699C4.74382 4.16699 2.19382 8.90283 1.74132 9.84033C1.71684 9.89011 1.7041 9.94485 1.7041 10.0003C1.7041 10.0558 1.71684 10.1105 1.74132 10.1603C2.19299 11.0978 4.74299 15.8337 9.99966 15.8337C15.2563 15.8337 17.8055 11.0978 18.258 10.1603C18.2825 10.1105 18.2952 10.0558 18.2952 10.0003C18.2952 9.94485 18.2825 9.89011 18.258 9.84033C17.8063 8.90283 15.2563 4.16699 9.99966 4.16699Z"
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}