import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PathInterface} from "@app/interfaces/interfaces";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {useLocation} from "react-router-dom";
import {ProductDomain} from "@app/domains/product.domain";
import {ProductCard} from "@app/components/common/product-card/produt-cart.component";
import {Events, scrollSpy} from 'react-scroll';

const SearchProductsPage: React.FC = (props) => {
    const {t} = useTranslation()
    const location = useLocation()
    const [products, setProducts] = useState<Array<ProductDomain>>([])
    const [count, setCount] = useState<number>(1)
    const [page, setPage] = useState<number>(1)

    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        setProducts(location.state.products)
        setCount(location.state.count)
    })

    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "/products", title: t("products")},
        {path: "#", title: t('search')},
    ]

    useEffect(() => {
        Events.scrollEvent.register('begin', (to: any, element: any) => {
            console.log('begin', to, element);
        });
        Events.scrollEvent.register('end', (to: any, element: any) => {
            console.log('end', to, element);
        });
        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <S.LayoutMaster>
            <div className={'box'}>
                <RoutePathComponent paths={routePaths}/>

                <div className={'row'}>
                    <div className={'col w-100'}>
                        <div className={'d-flex'}>
                            {products.map((product, index) => (
                                <ProductCard product={product} key={index}/>
                            ))}
                        </div>

                    </div>
                </div>

            </div>

        </S.LayoutMaster>
    )
}

export default SearchProductsPage;