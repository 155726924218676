import styled from "styled-components";
import {Typography} from "antd";

export const Input = styled(Typography.Text)`
  width: 100%;

  textarea {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--black);
    width: 100%;
    background: var(--white);
    color: var(--black);
    font-family: "robotoRegular"!important;
    line-height: 24px;
    font-size: 16px;
  }
`;