import React from "react";

export const ShareSvg: React.FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4199 16.08C17.6599 16.08 16.9799 16.38 16.4599 16.85L9.32992 12.7C9.37992 12.47 9.41992 12.24 9.41992 12C9.41992 11.76 9.37992 11.53 9.32992 11.3L16.3799 7.19C16.9199 7.69 17.6299 8 18.4199 8C20.0799 8 21.4199 6.66 21.4199 5C21.4199 3.34 20.0799 2 18.4199 2C16.7599 2 15.4199 3.34 15.4199 5C15.4199 5.24 15.4599 5.47 15.5099 5.7L8.45992 9.81C7.91992 9.31 7.20992 9 6.41992 9C4.75992 9 3.41992 10.34 3.41992 12C3.41992 13.66 4.75992 15 6.41992 15C7.20992 15 7.91992 14.69 8.45992 14.19L15.5799 18.35C15.5299 18.56 15.4999 18.78 15.4999 19C15.4999 20.61 16.8099 21.92 18.4199 21.92C20.0299 21.92 21.3399 20.61 21.3399 19C21.3399 17.39 20.0299 16.08 18.4199 16.08Z"
                />
        </svg>

    )
}