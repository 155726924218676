export const InquiryProductTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('title'),
    dataIndex: 'title',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('sku'),
    dataIndex: 'sku',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('quantity'),
    dataIndex: 'quantity',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('price'),
    dataIndex: 'price',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('total-price'),
    dataIndex: 'totalPrice',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('description'),
    dataIndex: 'description',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: '',
    dataIndex: 'action',
    width: '15%',
  },
];

export const InquiryTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('code'),
    dataIndex: 'code',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('total-price'),
    dataIndex: 'totalPrice',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('created-at'),
    dataIndex: 'createdAt',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];

export const InquiryVersionTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('inquiries.code'),
    dataIndex: 'code',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('inquiries.totalPrice'),
    dataIndex: 'totalPrice',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('inquiries.version'),
    dataIndex: 'version',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('inquiries.createdAt'),
    dataIndex: 'createdAt',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('common.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];

export interface InquiryProductData {
  id: number;
  index: number;
  title: string;
  sku: string;
  quantity: number;
  price: string;
  totalPrice: string;
  discount: number;
  description: string;
  action: JSX.Element;
}

export interface InquiryData {
  id: number;
  index: number;
  status: string;
  code: string;
  version: string;
  totalPrice: string;
  user: string;
  email: string;
  action: JSX.Element;
}

export interface InquiryVersionData {
  id: number;
  index: number;
  status: string;
  code: string;
  version: string;
  totalPrice: string;
  createdAt: string;
  action: JSX.Element;
}
