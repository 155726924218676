import React from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import AuthComponent from "@app/components/auth/auth.component";

const LoginPage: React.FC = () => {
    const {t} = useTranslation()
    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "#", title: 'login'},
    ]

    return (
        <S.LayoutMaster>
            <div className={'box'}>
                <RoutePathComponent paths={routePaths}/>

                <AuthComponent/>

            </div>

        </S.LayoutMaster>
    )
}

export default LoginPage;