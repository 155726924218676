import React from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import {useLocation} from "react-router-dom";

const AboutUsPage: React.FC = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "#", title: t('about-us')},
    ]


    return (
        <S.LayoutPage>
            <div className={'box-route'}>
                <RoutePathComponent paths={routePaths}/>
            </div>

            <div className={'header'}>
                <p>{t('about-us')}</p>
            </div>

        </S.LayoutPage>
    )
}

export default AboutUsPage;