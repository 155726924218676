import {httpApi} from "@app/api/http.api";
import {InquiryDomain} from "@app/domains/inquiry.domain";

export interface InquiryResult {
    success: boolean,
    message: string,
    code: number,
    data: {
        count: number,
        inquiries: InquiryDomain[]
    }
}


export const UserAccountInquiries = (page: number): Promise<InquiryResult> =>
    httpApi.get<InquiryResult>('user-account/inquiries?page=' + page).then(({data}) => data);