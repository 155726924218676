import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {WithLoading} from "@app/hocs/with-loading.hoc";
import HomeLayout from '@app/components/layouts/home/home.layout';
import HomePage from '@app/pages/home/index.page';
import MainLayout from '@app/router/layout';
import SingleProductPage from '@app/pages/products/single/index.page';
import CartPage from '@app/pages/cart/cart.page';
import RequestOrderPage from '@app/pages/cart/request-order/index.page';
import RequestOrderDetailPage from '@app/pages/cart/request-order/detail.page';
import UserAccountPage from "@app/pages/user-account/user-account.page";
import UserAccountLayout from '@app/components/layouts/user-account/user-account.layout';
import RequireAuth from "@app/router/RequireAuth";
import UserAccountInquiryPage from "@app/pages/user-account/inquiries/inquiries.page";
import UserAccountInquiryDetailPage from "@app/pages/user-account/inquiries/inquiry-detail.page";
import WishListPage from "@app/pages/user-account/wish-list/wish-list.page";
import ProfilePage from "@app/pages/user-account/profile/profile.page";
import LoginPage from "@app/pages/auth/login.page";
import OtherPage from "@app/pages/page/page.page";
import AboutUsPage from "@app/pages/page/about-us.page";
import ContactUsPage from "@app/pages/page/contact-us.page";
import ProductCategoryPage from "@app/pages/products/product-category/product-category.page";
import BrandPage from "@app/pages/brand/brand.page";
import AdvanceSearchPage from "@app/pages/advance-search/advance-search.page";
import HotProductsPage from "@app/pages/products/hot-products/hot-products.page";
import NewProductsPage from "@app/pages/products/new-products/new-products.page";
import SearchProductsPage from "@app/pages/products/search-products/search-products.page";

export const AppRouter: React.FC = () => {

    const _HomeLayoutFallBack = (
        <MainLayout>
            <HomeLayout/>
        </MainLayout>
    );

    const _HomePage = WithLoading(HomePage)
    const _SingleProductPage = WithLoading(SingleProductPage)
    const _CartPage = WithLoading(CartPage)
    const _RequestOrderPage = WithLoading(RequestOrderPage)
    const _RequestOrderDetailPage = WithLoading(RequestOrderDetailPage)

    const ProtectedLayout = (
        <RequireAuth>
            <UserAccountLayout/>
        </RequireAuth>
    );
    const _UserAccountPage = WithLoading(UserAccountPage)
    const _UserAccountInquiriesPage = WithLoading(UserAccountInquiryPage)
    const _UserAccountInquiryDetailPage = WithLoading(UserAccountInquiryDetailPage)
    const _UserAccountWishListPage = WithLoading(WishListPage)
    const _UserAccountProfilePage = WithLoading(ProfilePage)
    const _LoginPage = WithLoading(LoginPage)


    const _OtherPage = WithLoading(OtherPage)
    const _AboutUsPage = WithLoading(AboutUsPage)
    const _ContactUsPage = WithLoading(ContactUsPage)

    const _ProductCategoryPage = WithLoading(ProductCategoryPage)
    const _BrandPage = WithLoading(BrandPage)
    const _AdvanceSearchPage = WithLoading(AdvanceSearchPage)
    const _HotProductsPage = WithLoading(HotProductsPage)
    const _NewProductsPage = WithLoading(NewProductsPage)
    const _SearchProductPage = WithLoading(SearchProductsPage)

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={_HomeLayoutFallBack}>
                    <Route index element={<_HomePage/>}/>
                   
                    {/*Smart products*/}
                    <Route path="products">
                        <Route path=":slug" element={<_SingleProductPage/>}/>
                        <Route path="search" element={<_SearchProductPage/>}/>
                    </Route>
                    <Route path="product-category/:slug" element={<_ProductCategoryPage/>}/>
                    <Route path="brands/:slug" element={<_BrandPage/>}/>
                    <Route path="advance-search" element={<_AdvanceSearchPage/>}/>
                    <Route path="hot-products" element={<_HotProductsPage/>}/>
                    <Route path="new-products" element={<_NewProductsPage/>}/>

                    {/*Smart products*/}
                    <Route path="cart">
                        <Route path="" element={<_CartPage/>}/>
                        <Route path="request-order">
                            <Route path="" element={<_RequestOrderPage/>}/>
                            <Route path="detail/:uuid" element={<_RequestOrderDetailPage/>}/>
                        </Route>
                    </Route>


                    <Route path="auth/login" element={<_LoginPage/>}/>

                    <Route path="about-us" element={<_AboutUsPage/>}/>
                    <Route path="contact-us" element={<_ContactUsPage/>}/>
                    <Route path=":slug" element={<_OtherPage/>}/>
                </Route>
                <Route path="/user-account" element={ProtectedLayout}>
                    <Route path="" element={<_UserAccountPage/>}/>

                    <Route path="inquiries">
                        <Route path="" element={<_UserAccountInquiriesPage/>}/>
                        <Route path=":uuid" element={<_UserAccountInquiryDetailPage/>}/>
                    </Route>
                    <Route path="wish-list" element={<_UserAccountWishListPage/>}/>
                    <Route path="profile" element={<_UserAccountProfilePage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
