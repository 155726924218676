import styled from "styled-components";
import {Layout} from "antd";
import {media} from "@app/assets/styles/themes/constants";
import {BaseButton} from "@app/components/common/BaseButton/BaseButton";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;
  
  .box{
    width: 80%;
    margin: 0 auto 0 auto;

    @media only screen and ${media.xl}{
      width: 80%;
    }

    @media only screen and ${media.md}{
      width: 95%;
    }

    @media only screen and ${media.sm}{
      width: 95%;
    }
    
    @media only screen and ${media.xs}{
      width: 95%;
    }
    
    .row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      margin: 10px auto 10px auto;
    }
  }
  
`;

export const Thumbnail = styled(Layout)`
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .icons {
    display: table;
    align-self: center;
    align-items: center;
    margin: 10px;

    div {
      cursor: pointer;

      &.stroke {
        svg {
          stroke: var(--black);
          margin-bottom: 30px;
        }
      }

      &.fill {
        svg {
          fill: var(--black);
          margin-bottom: 30px;
        }
      }

      &.all {
        svg {
          fill: var(--black);
          stroke: var(--black);
          margin-bottom: 30px;
        }
      }
    }
    
    .image{
      width: 428px;
      height: 328px;
      margin: 5px;
    }
  }
`;

export const Info = styled(Layout)`
  .categories {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 6px 16px;
    gap: 8px;
    margin-right: 10px;

    a {
      border: 1px solid var(--black);
      padding: 10px;
      font-family: "robotoRegular" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: var(--black);
      border-radius: 8px;
      text-decoration: none;
    }
  }

  .product-title {
    font-family: "robotoMedium" !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: var(--black);
    margin-top: 10px;
  }

  .product-short-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: "robotoRegular" !important;
    color: var(--black);
    margin-top: 30px;
  }

  .short-info {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;

    p {
      font-family: "robotoMedium" !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .alert {
    display: flex;
    margin-top: 10px;
    font-family: "robotoMedium" !important;
    color: var(--red-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    b {
      svg {
        stroke: var(--red-color);
        margin-right: 5px;
      }
    }
  }
`;

export const Basket = styled(Layout)`
  margin-left: 20px;

  .alert {
    background: var(--red-light-color);
    border-radius: 8px;
    padding: 10px 24px;

    p {
      font-family: "robotoMedium" !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--black);
    }

    strong {
      font-family: "robotoMedium" !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--black);
    }
  }

  .seller {
    background: var(--white);
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);

    .title {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      font-family: "robotoRegular" !important;
      color: var(--black);
    }

    .logo {
      width: 87.83px;
      height: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;

      .add-to-card {
        border-radius: 8px;
        border: 1px solid var(--black);
        padding: 10px 24px;
        align-items: center;
        height: 50px;

        span {
          color: var(--primary-color);
          width: 9.333px;
          height: 1.333px;
          font-size: 22px;
          font-family: "robotoMedium" !important;
          margin-right: 10px;
          margin-left: 10px;
        }

        .pointer {
          cursor: pointer;
        }
      }

      .price {
        display: flex;
        justify-content: space-between;
        align-self: center;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          font-family: "robotoRegular" !important;
          color: var(--black);
        }

        strong {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          font-family: "robotoMedium" !important;
          color: var(--black);
          margin-left: 10px;
        }
      }
    }

    .order-row {
      width: 100%;
      align-self: center;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;

      .order {
        border-radius: 10px;
        background: var(--primary-color);
        padding: 15px;
        border: none;
        width: 70%;
        cursor: pointer;
        margin: 0 auto 0 auto;


        span {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          font-family: "robotoMedium" !important;
          color: var(--white);
        }
      }
    }

    .alert-row {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 10px;

      .p-red {
        display: flex;
        cursor: pointer;

        b svg {
          stroke: var(--red-color);
        }

        p {
          color: var(--red-color);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          font-family: "robotoRegular" !important;
          margin-left: 5px;
        }
      }

      .p-black {
        display: flex;
        cursor: pointer;

        b svg {
          stroke: var(--black);
        }

        p {
          color: var(--black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          font-family: "robotoRegular" !important;
          margin-left: 5px;
        }
      }
    }
  }
`;

export const Tabs = styled(Layout)`
  background: var(--lighter-gray-color);
  padding: 10px 24px;

  .box {
    width: 80%;
    margin: 0 auto 0 auto;
    display: flex;

    .item {
      width: auto;
      cursor: pointer;
      transition: 0.3s;
      border: 3px solid var(--white);

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        font-family: "robotoMedium" !important;
        color: var(--black);
        text-align: center;
        padding: 10px;
      }

      &.active {
        border-bottom: 3px solid var(--primary-color);
      }
    }
  }
`;

export const TabView = styled(Layout)`
  margin-top: 40px;

  .title {
    display: flex;

    .green-box {
      width: 14%;
      height: 48px;
      flex-shrink: 0;
      background: var(--primary-color);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    p {
      display: flex;
      color: var(--black);
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      margin-left: -35px;
      height: 40px;
      align-self: center;
      font-family: "robotoMedium" !important;
    }
  }

  .title-2 {
    display: flex;

    .green-box {
      width: 40%;;
      height: 48px;
      flex-shrink: 0;
      background: var(--primary-color);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    p {
      display: flex;
      color: var(--black);
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      height: 40px;
      align-self: center;
      margin-left: 20px;
      text-align: center;
      font-family: "robotoMedium" !important;
    }
  }

  .title-3 {
    display: flex;
    justify-content: space-between;

    .row {
      display: flex;
      width: 80%;

      .green-box {
        width: 14%;
        height: 48px;
        flex-shrink: 0;
        background: var(--primary-color);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      p {
        display: flex;
        color: var(--black);
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        margin-left: -35px;
        height: 40px;
        align-self: center;
        font-family: "robotoMedium" !important;
      }
    }


    .add-comment {
      background: var(--primary-color);
      border: none;
      border-radius: 8px;
      padding: 10px 24px;
      position: relative;
      right: 0;
      margin-right: 10%;
      cursor: pointer;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: space-between;

      b svg {
        fill: var(--white);
        margin-right: 5px;
        align-items: center;
        align-self: center;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--white);
        font-family: "robotoMedium" !important;
      }
    }
  }

  .box {
    width: 80%;
    margin: 30px auto 0 auto;

    strong {
      font-family: "robotoMedium" !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    p {
      font-family: "robotoRegular" !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    ul li {
      font-family: "robotoRegular" !important;
      color: var(--black);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .row {
      display: flex;
      
      .file {
        display: flex;
        text-decoration: none;
        align-items: center;
        margin: 5px;

        b {
          svg {
            margin-right: 5px;
          }
        }

        span {
          font-family: "robotoRegular" !important;
          color: var(--black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      
      .image-gallery{
        
      }
    }

  }

  .box-table {
    width: 60%;
    margin: 30px auto 0 auto;

    table {
      width: 100%;

      tbody {
        padding: 10px 24px;

        tr {
          padding: 10px 24px;
          justify-content: space-between;
          background: #F8FAF6;

          &.green {
            background: rgba(231, 255, 242, 0.50);
          }

          td {
            color: var(--black);
            font-family: "robotoRegular" !important;
            padding: 13px 0px 11px 16px;
          }

          td:last-child {
            color: var(--black);
            font-family: "robotoRegular" !important;
            padding: 13px 11px 0px 16px;
            text-align: right;
          }
        }
      }
    }
  }

  .box-comments {
    width: 80%;
    margin: 30px auto 0 auto;

    .show-more {
      display: flex;
      text-decoration: none;
      align-self: center;
      padding: 10px 24px;
      align-items: center;
      align-content: center;

      b {
        margin-top: 5px;
        margin-right: 10px;

        svg {
          fill: var(--primary-color);
        }
      }

      span {
        margin-top: -5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: var(--primary-color);
        font-family: "robotoRegular" !important;
      }
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  width: 70%;
  cursor: pointer;
  margin: 0 auto 0 auto;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;