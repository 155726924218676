import {httpApi} from "@app/api/http.api";
import {HomeDomain} from "@app/domains/home.domain";


export interface PageResponse {
    success: boolean,
    code: number,
    message: string,
    data: {
        id: number,
        uuid: string,
        title: string,
        slug: string,
        content: string
    }
}

export interface ContactUsRequest {
    fullName: string,
    email: string,
    mobile: string,
    message: string
}

export const HomeProducts = (): Promise<HomeDomain> =>
    httpApi.get<HomeDomain>('home').then(({data}) => data);

export const Page = (slug: string): Promise<PageResponse> =>
    httpApi.get<PageResponse>('home/page/' + slug).then(({data}) => data);

export const ContactUs = (): Promise<any> =>
    httpApi.get<any>('home/contact-us').then(({data}) => data);

export const ContactUsMessage = (request: ContactUsRequest): Promise<any> =>
    httpApi.post<any>('home/contact-us/message', request).then(({data}) => data);
