import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';
import RoutePathComponent from "@app/components/common/route-path.component";
import {PathInterface} from "@app/interfaces/interfaces";
import {Page} from "@app/api/home.api";
import {useLocation} from "react-router-dom";

const OtherPage: React.FC = () => {
    const {t} = useTranslation()
    const location = useLocation()

    const [page, setPage] = useState<{
        id: number,
        uuid: string,
        title: string,
        slug: string,
        content: string
    }>()

    useEffect(() => {
        Page(location.pathname.substring(1)).then(result => {
            setPage(result.data)
        }).catch(error => {
            console.log(error)
        })
    }, [location])

    const routePaths: PathInterface[] = [
        {path: "/", title: t("home")},
        {path: "#", title: page?.title ?? ""},
    ]


    return (
        <S.LayoutPage>
            <div className={'box-route'}>
                <RoutePathComponent paths={routePaths}/>
            </div>

            <div className={'header'}>
                <p>{page?.title ?? ""}</p>
            </div>

            <div className={'box'} style={{padding: '30px'}}>
                <div className={'content'} dangerouslySetInnerHTML={{__html: page?.content ?? ""}}></div>
            </div>
        </S.LayoutPage>

    )
}

export default OtherPage;