import {ProductDomain} from "@app/domains/product.domain";
import {createAction, createSlice, PrepareAction} from "@reduxjs/toolkit";

interface ProductState {
    products: Array<ProductDomain>;
}

const initialState: ProductState = {
    products: []
}

export const setHotProducts = createAction<PrepareAction<Array<ProductDomain>>>('products/setHotProduct', (products: Array<ProductDomain>) => {
    return {
        payload: products,
    };
});

export const hotProductsSlice = createSlice({
    name: 'hot-products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setHotProducts, (state, action) => {
            state.products = action.payload;
        });
    },
});

export default hotProductsSlice.reducer;