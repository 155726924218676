import styled from "styled-components";
import {Layout} from "antd";
import {BaseButton} from "@app/components/common/BaseButton/BaseButton";

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin: 0 auto 0 auto;

  .title {
    color: var(--white);
    font-family: "robotoRegular" !important;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: var(--primary-color);
    margin: 10px 30px 10px 30px
  }
  
  .box {
    width: 80%;
    margin: 0 auto 0 auto;

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export const SubmitButton = styled(BaseButton)`
  border-radius: 10px;
  background: var(--primary-color);
  padding: 15px;
  border: none;
  cursor: pointer; 
  width: 100%;
  margin: 30px auto 30px auto;


  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "robotoMedium" !important;
    color: var(--white);
  }

  &:hover {
    background: var(--primary-color);
  }
`;