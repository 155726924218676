import styled from "styled-components";
import {Layout} from "antd";

export const Comment = styled(Layout)`
  width: 100%;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  border-radius: 8px;
  background: var(--white);
  padding: 10px 14px;
  margin-bottom: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 5px;

    .author {
      display: flex;

      img {
        border-radius: 1000px;
        width: 40px;
        height: 40px;
      }

      div {
        display: table;
        align-self: center;
        margin-left: 10px;

        p:first-child {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          font-family: "robotoMedium" !important;
          color: var(--black);
        }

        p:last-child {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          font-family: "robotoMedium" !important;
          color: var(--border-base-color);
        }
      }
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      svg {
        fill: var(--border-base-color);
      }
    }
  }

  .body {
    margin: 30px auto 30px auto;
    padding: 10px 24px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      font-family: "robotoRegular" !important;
      color: var(--black);
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;

    .like {
      display: flex;
      margin-right: 15px;
      cursor: pointer;

      b {
        svg {
          fill: var(--black);
        }
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-family: "robotoRegular" !important;
        color: var(--black);
        margin-left: 5px;
      }
    }

    .dis-like {
      display: flex;
      margin-right: 15px;
      cursor: pointer;

      b {
        svg {
          fill: var(--black);
        }
      }

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-family: "robotoRegular" !important;
        color: var(--black);
        margin-left: 5px;
      }
    }
  }
`;