import React, {useRef} from 'react';
import * as S from './styles.style';
import {useTranslation} from "react-i18next";
import {UploadSvg} from "@app/assets/images/svgs/upload.svg";

export const FileComponent: React.FC<{
    error: string,
    types: string,
    max: number,
    onChange: any
}> = (props) => {

    const {t} = useTranslation()
    const ref = useRef(null)
    const handleOnClick = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current?.click();
    }

    const handleFileChange = (e: any) => {
        const files = e.target.files;
        if (files.length > 0) {
            props.onChange(files[0]);
        } else {
            props.onChange(null);
        }
    };


    return (
        <S.File onClick={handleOnClick}>
            <div className={'select'}>
                <b>
                    <UploadSvg/>
                </b>
                <span>{t('select-file')}</span>
            </div>

            <span className={'description'}>Maximum file size {props.max}MB. File types accepted {props.types}.</span>

            <input type={"file"} style={{display: "none"}} accept={props.types} max={props.max}
                   onChange={handleFileChange} ref={ref}/>
        </S.File>
    );
};
