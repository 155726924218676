import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;

  .flex {
    display: flex;
    
    .main{
      width: calc(100% - 300px);
    }
  }
`;