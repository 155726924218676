import React, { Suspense } from 'react';
import { Loading } from '@app/components/common/loading.component';

type ReturnType<T> = (props: T) => JSX.Element;

//eslint-disable-next-line @typescript-eslint/ban-types
export const WithLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
    return (props: T) => (
    <Suspense fallback={<Loading />}>
        {/* eslint-disable-next-line @typescript-eslint/ban-types */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
      <Component {...props}  />
    </Suspense>
  );
};
