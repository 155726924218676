import {createAction, createSlice, PrepareAction} from "@reduxjs/toolkit";
import {CartDomain} from "@app/domains/cart.domain";

interface CartState {
    cart: CartDomain;
}

const initialState: CartState = {
    cart: {
        id: 1,
        uuid: '1',
        currency: '1',
        totalPrice: 1,
        createdAt: new Date(),
        updatedAt: new Date(),
        user: {
            id: 1,
            firstName: '',
            lastName: '',
            email: '',
            phone: 1
        },
        carts: []
    }
}

export const setCart = createAction<PrepareAction<CartDomain>>('carts/setCart', (cart: CartDomain) => {
    return {
        payload: cart,
    };
});

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setCart, (state, action) => {
            state.cart = action.payload;
        });
    },
});

export default cartSlice.reducer;