import React, { useEffect, useState } from "react";
import { ReactComponent as LogoSvg } from "@app/assets/images/svgs/logo-1.svg"
import UserIcon from "@app/assets/images/svgs/user.svg"
import CartIcon from "@app/assets/images/svgs/cart.svg"
import { SearchSvg } from "@app/assets/images/svgs/search.svg"
import * as S from "./header.styles"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowSVG } from "@app/assets/images/svgs/arrow.svg"
import { SendInquirySvg } from "@app/assets/images/svgs/send-inquiry.svg"
import { BuyingToolsSvg } from "@app/assets/images/svgs/buying-tools.svg"
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { CategoryDomain } from "@app/domains/category.domain";
import { GetCategories } from "@app/api/category.api";
import { BrandDomain } from "@app/domains/brand.domain";
import { GetBrands } from "@app/api/brand.api";
import Modal from 'react-modal';
import AuthComponent from "@app/components/auth/auth.component";
import { readToken } from "@app/utils/local-storage.service";
import { SearchProduct } from "@app/api/product.api";
import { MenuSvg } from "@app/assets/images/svgs/menu.svg";
import SidebarLayout from "@app/components/layouts/home/header/mobile/sdebar.layout";
import { timeout } from "workbox-core/_private";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        borderRadius: '10px',
        zIndex: '1000',
        border: 'none',
        padding: '0px',
        marginTop: '60px'
    },
    overlay: { zIndex: 1000 }
};

const MainHeaderLayout: React.FC = () => {
    const { t } = useTranslation()
    const [openProductsSubMenu, setOpenProductsSubMenu] = useState<boolean>(false)
    const [openBrandSubMenu, setOpenBrandSubMenu] = useState<boolean>(false)
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false)
    const [overlay, setOverlay] = useState<boolean>(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const searchRef = React.useRef<any>(null);
    const location = useLocation()
    const navigate = useNavigate()

    const handleOverlay = () => {
        setOverlay(!overlay)

    }

    const handleOpenProducts = () => {
        setOpenProductsSubMenu(!openProductsSubMenu)

        if (openBrandSubMenu) {
            setOpenBrandSubMenu(!openBrandSubMenu)
        }
        else {
            handleOverlay()
        }
    }

    const handleOpenbrands = () => {
        setOpenBrandSubMenu(!openBrandSubMenu)

        if (openProductsSubMenu) {
            setOpenProductsSubMenu(!openProductsSubMenu)
        }
        else {
            handleOverlay()
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleToggle = () => {
        setOpen((open) => !open);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const [categories, setCategories] = useState<Array<CategoryDomain>>([])
    const [brands, setBrands] = useState<Array<BrandDomain>>([])
    useEffect(() => {
        GetCategories().then(result => {
            setCategories(result.data)
            setSelectedCategory(result.data[0])
        })
        GetBrands().then(result => {
            setBrands(result.data)
        })
    }, [])

    const [selectedCategory, setSelectedCategory] = useState<CategoryDomain>()
    const handleSelectCategory = (category: CategoryDomain) => {
        setSelectedCategory(category)
    }

    const [openAuthModal, setOpenAuthModal] = useState<boolean>(false)
    const handleOpenAuthModal = () => {
        if (readToken() === '') {
            setOpenAuthModal(true)
        } else {
            navigate('/user-account')
        }
    }

    const [visible, setVisible] = useState(true)
    useEffect(() => {
        let position = 0
        const handleScroll = (e: any) => {
            const moving = e.target.scrollTop

            setVisible(position > moving);
            position = moving
        };
        window.addEventListener("scroll", handleScroll, true);
        return (() => {
            window.removeEventListener("scroll", handleScroll, true);
        })
    }, [])

    const handleCloseProductMenu = () => {
        setOpenProductsSubMenu(false)
    }

    const [multiLineSearch, setMultiLineSearch] = useState<boolean>(false)

    const changeMultiLineSearch = () => {
        setMultiLineSearch(!multiLineSearch)
    }

    const searchProduct = (event: any) => {
        if (event.key === 'Enter' && !multiLineSearch) {
            SearchProduct(event.target.value, multiLineSearch, 1).then(result => {
                navigate('products/search', { state: { ...result.data, title: event.target.value } })
            })
        } else {
            if (event.key === 'Enter') {
                SearchProduct(event.target.value, multiLineSearch, 1).then(result => {
                    navigate('products/search', { state: { ...result.data, title: event.target.value } })
                })
            }
        }
    }

    const [search, setSearch] = useState('')
    const searchOnChangeHandler = (event: React.SyntheticEvent) => {
        const { value } = event.target as HTMLInputElement;
        setSearch(value);
    }

    const searchProductOnClick = () => {
        SearchProduct(search, multiLineSearch, 1).then(result => {
            navigate('products/search', { state: { ...result.data, title: search } })
        })
    }

    React.useEffect(() => {
        if (search && location.pathname !== '/products/search') {
            setSearch('');
        }

    }, [location.pathname])

    const handleOpenMobileMenu = () => {
        setOpenMobileMenu(!openMobileMenu)
    }

    React.useEffect(() => {
        if (overlay) {
            handleOverlay()
        }
    }, [location.pathname])

    return (
        <S.Header className={visible ? 'active' : 'hidden'}>
            <div className={`${overlay ? 'overlay' : ""} overlay-content`}>
                <div className="overlay-content-inner"></div>
            </div>
            <S.Navigation>
                <div className="navigation">
                    <Link className="logo-container" to={'/'}>
                        <LogoSvg className={'logo'} />
                    </Link>
                    <div className={'nav-links-container'}>
                        <div className={'menu-items-left'}>
                            <ul>
                                <li>
                                    <a onClick={handleOpenProducts}
                                        className={location.pathname.includes('products') ? 'active' : ''}>
                                        <span>{t('products')}</span>
                                        <b style={{ rotate: openProductsSubMenu ? '180deg' : '0deg' }}>
                                            <ArrowSVG />
                                        </b>
                                    </a>
                                </li>
                                <li className={location.pathname.includes('brands') ? 'active' : ''}>
                                    <a onClick={handleOpenbrands}>
                                        <span>{t('brands')}</span>
                                        <b style={{ rotate: openBrandSubMenu ? '180deg' : '0deg' }}>
                                            <ArrowSVG />
                                        </b>
                                    </a>
                                    <div className={`${openBrandSubMenu ? 'openSub' : ''
                                        } brands-sub-menu-container`} style={{ display: openBrandSubMenu ? 'block' : 'none' }}>

                                        <ul className={'brands-sub-menu'} >
                                            {brands.map((brand, index) => (
                                                <li key={index}>
                                                    <Link to={'/brands/' + brand.slug} state={brand}
                                                        onClick={() => setOpenBrandSubMenu(false)}>
                                                        {brand.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                                <li className={location.pathname.includes('how-to-buy') ? 'active' : ''}><Link
                                    to={'/how-to-buy'}><a><span>{t('how-to-buy')}</span></a></Link></li>
                                <li className={location.pathname.includes('about-us') ? 'active' : ''}><Link
                                    to={'/about-us'}><a><span>{t('about-us')}</span></a></Link></li>
                            </ul>
                            <div className={'menu-items-right'}>
                                <div>
                                    <a onClick={handleOpenAuthModal}>
                                        <img src={UserIcon} />
                                        {readToken() === '' ?
                                            <span>{t('login-register')}</span>
                                            :
                                            <span>{t('user-account')}</span>
                                        }
                                    </a>

                                    <Link to={'/cart'}>
                                        <img src={CartIcon} />
                                        <span>{t('cart')}</span>
                                    </Link>
                                </div>
                                {/* <div>
                                    <a>
                                        <span>{t('language-currency')}</span>
                                    </a>
                                </div> */}
                            </div>
                        </div>


                        <div className={`${openProductsSubMenu ? 'openSub' : ''} products-sub-menu`} style={{ display: openProductsSubMenu ? 'flex' : 'none' }}>
                            <div className={'first-col'}>
                                <ul>
                                    {
                                        categories.map((category, index) => (
                                            <li className={selectedCategory ? (selectedCategory.id === category.id ? 'active' : '') : ''} key={index}>
                                                <a onClick={() => handleSelectCategory(category)}>
                                                    {category.title}
                                                    <b style={{ rotate: '-90deg' }}>
                                                        <ArrowSVG />
                                                    </b>
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className={'second-col'}>
                                <ul>
                                    {
                                        selectedCategory && selectedCategory.categories.map((category, index) => (
                                            <li key={index}>
                                                <Link to={'/product-category/' + category.slug} state={category}
                                                    onClick={handleCloseProductMenu}>
                                                    {category.title}
                                                </Link>

                                                <ul>
                                                    {category.categories.map((_category, _index) => (
                                                        <li key={index}>
                                                            <Link to={'/product-category/' + _category.slug} state={_category}
                                                                onClick={handleCloseProductMenu}>
                                                                {_category.title}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>



                    </div>
                    <div className={'nav-mobile'}>
                        <b onClick={handleOpenMobileMenu}>
                            <MenuSvg />
                        </b>
                        <b>
                            <SearchSvg />
                        </b>
                    </div>
                </div>

                <div className={'search-container'}>
                    <div className={multiLineSearch ? 'search-box height-100' : 'search-box'}>
                        {multiLineSearch ?
                            <textarea className={multiLineSearch ? 'height-100' : ''} rows={multiLineSearch ? 5 : 1}
                                placeholder={t('search-by-product-code')} onKeyDown={searchProduct} />
                            :
                            <input ref={searchRef} onChange={searchOnChangeHandler} placeholder={t('search-by-product-code')} value={search} onKeyDown={searchProduct} />
                        }

                        <b onClick={searchProductOnClick}>
                            <SearchSvg />
                        </b>
                    </div>
                    <div className={'search-box-footer'}>
                        <div>

                            <input type={"checkbox"} onChange={changeMultiLineSearch} />
                            <label>{t("Multiple Search")}</label>
                        </div>
                        <div>

                            <Link to={'/advance-search'} className={'advance-search'}>{t('advance-search')}</Link>
                        </div>
                    </div>
                    <div className={'search-footer'}>
                        <Button
                            ref={anchorRef}
                            className={'buying-tools'}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <b>
                                <BuyingToolsSvg />
                            </b>
                            {t('buying-tools')}
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={handleClose}>{t('quick-buy')}</MenuItem>
                                                <MenuItem onClick={handleClose}>{t('request-quote')}</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>

                        <Link to={'/send-inquiry'} className={'send-inquiry'}>
                            <b>
                                <SendInquirySvg />
                            </b>
                            {t('send-inquiry')}
                        </Link>

                    </div>
                </div>


                <SidebarLayout showMenu={openMobileMenu} />

            </S.Navigation>

            <Modal
                isOpen={openAuthModal}
                onRequestClose={() => setOpenAuthModal(!openAuthModal)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <AuthComponent />
            </Modal>

        </S.Header>
    )
}

export default MainHeaderLayout;
