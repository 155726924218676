import useEmblaCarousel from "embla-carousel-react";
import { PropsWithChildren } from "react";

// 1. define the props
type Props = { options?: any } & PropsWithChildren<any>;

const ProductSlider = ({ children, options }: Props) => {
    // 2. initialize EmblaCarousel using the custom hook
    const [emblaRef] = useEmblaCarousel({
        slidesToScroll: 1,
        align: "start",
        ...options,
    });

    return (
        <div ref={emblaRef}>
            <div style={{display: 'flex', gap: 10}}>{children}</div>
        </div>
    );
};
export default ProductSlider;