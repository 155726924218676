import React from "react";
import {useTranslation} from "react-i18next";
import * as S from './styles.style';

const UserAccountPage: React.FC = (props) => {
    const {t} = useTranslation()

    return(
        <S.LayoutMaster>
            <p style={{margin: '100px', color: '#000'}}>dsfgsdfg</p>
        </S.LayoutMaster>
    )
}

export default UserAccountPage;