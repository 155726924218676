import styled from "styled-components";
import {Layout} from "antd";

export const RouterPath = styled(Layout)`
  background: var(--white);
  width: 100%;
  margin-top: 0.5rem;
  display: inline-flex;

  a {
    display: inline-flex;
    text-decoration: none;

    span {
      color: var(--primary-color);
    }

    svg {
      fill: var(--primary-color);
      margin-right: 5px;
      margin-left: 5px;
      rotate: -90deg;
      align-self: center;
    }
    
    .gray{
      color: var(--deactivated-link-color);
    }
  }
`;