import {createAction, createSlice, PayloadAction, PrepareAction, createAsyncThunk} from "@reduxjs/toolkit";
import {CategoryDomain} from "@app/domains/category.domain";
import {GetCategories} from "@app/api/category.api";

interface CategoryState {
    categories: Array<CategoryDomain>;
}

export const getCategories = createAsyncThunk(
    'category/setCategory',
    async () => {
        try {
            return await GetCategories()
        } catch (err) {
            return null
        }
    }
)

const initialState: CategoryState = {
    categories: []
}

export const setCategories = createAction<PrepareAction<Array<CategoryDomain>>>('category/setCategory', (categories: Array<CategoryDomain>) => {
    return {
        payload: categories,
    };
});

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<CategoryState>): CategoryState => {
            return state
        },
    },
    extraReducers: (builder) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        builder.addCase(getCategories.fulfilled, (state, action) => {
            return action.payload?.data
        })
    },
});

export const {add} = categorySlice.actions
export default categorySlice.reducer;
