import React from "react";

export const LikeSvg: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7633 11.4915C21.157 12.0118 21.375 12.6493 21.375 13.3126C21.375 14.3649 20.7867 15.361 19.8398 15.9165C19.5961 16.0595 19.3185 16.1347 19.0359 16.1345H13.4156L13.5563 19.0149C13.5891 19.711 13.343 20.372 12.8648 20.8759C12.6302 21.1242 12.3471 21.3218 12.0331 21.4565C11.7191 21.5911 11.3807 21.6599 11.0391 21.6587C9.82031 21.6587 8.74219 20.8384 8.41875 19.6641L6.40547 12.3751H3.375C2.96016 12.3751 2.625 12.0399 2.625 11.6251L2.625 3.09383C2.625 2.67899 2.96016 2.34383 3.375 2.34383L17.468 2.34383C17.6836 2.34383 17.8945 2.38602 18.0891 2.47039C19.2047 2.94617 19.9242 4.03602 19.9242 5.24539C19.9242 5.5407 19.882 5.83133 19.7977 6.11258C20.1914 6.63289 20.4094 7.27039 20.4094 7.93367C20.4094 8.22899 20.3672 8.51961 20.2828 8.80086C20.6766 9.32117 20.8945 9.95867 20.8945 10.622C20.8898 10.9173 20.8477 11.2102 20.7633 11.4915ZM4.3125 4.03133V10.6876H6.21094V4.03133H4.3125ZM19.2281 12.3048L18.7148 11.8595L19.0406 11.2641C19.148 11.068 19.2036 10.8478 19.2023 10.6243C19.2023 10.2376 19.0336 9.86961 18.743 9.61649L18.2297 9.17117L18.5555 8.57586C18.6628 8.37976 18.7185 8.15957 18.7172 7.93602C18.7172 7.5493 18.5484 7.18133 18.2578 6.9282L17.7445 6.48289L18.0703 5.88758C18.1776 5.69148 18.2333 5.47128 18.232 5.24774C18.232 4.72274 17.9227 4.2493 17.4445 4.03367L7.71094 4.03367V10.7626L10.043 19.2118C10.1031 19.4284 10.2322 19.6194 10.4107 19.756C10.5891 19.8926 10.8073 19.9673 11.032 19.9688C11.2102 19.9688 11.3859 19.9173 11.5266 19.8118C11.7586 19.6384 11.8828 19.3759 11.8687 19.097L11.6437 14.447L19.0125 14.447C19.4297 14.1915 19.6875 13.7602 19.6875 13.3126C19.6875 12.9259 19.5188 12.5602 19.2281 12.3048Z"
                />
        </svg>

    )
}