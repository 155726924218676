import {httpApi} from "@app/api/http.api";
import {BrandDomain} from "@app/domains/brand.domain";

export interface BrandResult {
    success: boolean,
    message: string,
    code: number,
    data: Array<BrandDomain>
}


export const GetBrands = (): Promise<BrandResult> =>
    httpApi.get<BrandResult>('home/brands/').then(({data}) => data);