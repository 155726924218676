import styled from "styled-components";
import {Layout} from "antd";

export const File = styled(Layout)`
  border: 1px dashed var(--border-base-color);
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto 0 auto;
  align-items: center;
  align-self: center;

  .select {
    display: flex;
    background: var(--light-purpol);
    padding: 20px;
    border-radius: 15px;
    align-self: center;
    width: 18%;
    align-items: center;
    align-content: center;

    b {
      svg {
        fill: var(--potpule-2);
      }
    }
    
    span {
      color: var(--dark-porpul);
      text-align: center;
      font-family: "robotoMedium" !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }
  }
  
  .description{
    color: var(--black);
    font-family: "robotoMedium"!important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 30px;
    text-align: center;
  }
`;