import {createAction, createSlice, PayloadAction, PrepareAction, createAsyncThunk} from "@reduxjs/toolkit";
import {GetIpAddress} from "@app/api/ip.api";

interface IpState {
    ip: string;
}

export const getIp = createAsyncThunk(
    'ip/getIp',
    async () => {
        try {
            return await GetIpAddress()
        } catch (err) {
            return {ip: ''}
        }
    }
)

const initialState: IpState = {
    ip: ''
}

export const setIp = createAction<PrepareAction<string>>('ip/setIp', (ip: string) => {
    return {
        payload: ip,
    };
});

export const ipSlice = createSlice({
    name: 'ip',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<IpState>): IpState => {
            return state
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getIp.fulfilled, (state, action) => {
            return action.payload
        })
    },
});

export const {add} = ipSlice.actions
export default ipSlice.reducer;
