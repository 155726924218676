import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import productsReducer from '@app/store/slices/products.slice';
import newProductsReducer from '@app/store/slices/new-products.slice';
import hotProductsReducer from '@app/store/slices/hot-products.slice';
import CartReducer from '@app/store/slices/cart.slice';
import IpReducer from '@app/store/slices/ip.slice';
import CategoryReducer from '@app/store/slices/category.slice';

export default {
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  products: productsReducer,
  newProducts: newProductsReducer,
  hotProducts: hotProductsReducer,
  cart: CartReducer,
  ip: IpReducer,
  categories: CategoryReducer,
};
