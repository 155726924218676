import React from "react";

export const TicketSvg: React.FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.00065 9.33338H9.33398V8.00004H4.00065V9.33338ZM4.00065 7.33338H12.0007V6.00004H4.00065V7.33338ZM4.00065 5.33338H12.0007V4.00004H4.00065V5.33338ZM1.33398 14.6667V2.66671C1.33398 2.30004 1.46465 1.98604 1.72598 1.72471C1.98732 1.46338 2.3011 1.33293 2.66732 1.33338H13.334C13.7007 1.33338 14.0147 1.46404 14.276 1.72538C14.5373 1.98671 14.6678 2.30049 14.6673 2.66671V10.6667C14.6673 11.0334 14.5367 11.3474 14.2753 11.6087C14.014 11.87 13.7002 12.0005 13.334 12H4.00065L1.33398 14.6667ZM3.43398 10.6667H13.334V2.66671H2.66732V11.4167L3.43398 10.6667Z"
              />
        </svg>

    )
}