import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/assets/styles/themes/constants';

export const LayoutMaster = styled(Layout)`
  background: var(--white);
  width: 100%;
  overflow-x: hidden;
`;

export const LayoutMain = styled(Layout)`

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;
