import React from "react";
import * as S from './styles.style';
import { useTranslation } from "react-i18next";
import { PathInterface } from "@app/interfaces/interfaces";
import { Link } from "react-router-dom";
import { ArrowSVG } from "@app/assets/images/svgs/arrow.svg"

const RoutePathComponent: React.FC<{ paths: PathInterface[] }> = ({ paths }) => {
    const { t } = useTranslation()

    return (
        <S.RouterPath>
            {paths.map((item, index) => (
                <Link to={item.path} key={index}>
                    {(paths.length - 1) !== index ?
                        <span>
                            {item.title}
                        </span>
                        :
                        <span className={'gray'}>
                            {item.title}
                        </span>
                    }

                    {(paths.length - 1) !== index ?
                        <b>
                            <ArrowSVG />
                        </b>
                        : null}
                </Link>
            ))}
        </S.RouterPath>
    )
}

export default RoutePathComponent;