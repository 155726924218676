import React from "react";

export const ReplySvg: React.FC = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3906 0.631218C13.8516 0.87028 13.5 1.40934 13.5 1.99997V4.99997H8.25C3.69375 4.99997 0 8.69372 0 13.25C0 18.5609 3.82031 20.9328 4.69688 21.4109C4.81406 21.4765 4.94531 21.5 5.07656 21.5C5.5875 21.5 6 21.0828 6 20.5765C6 20.225 5.79844 19.9015 5.54063 19.6625C5.1 19.25 4.5 18.425 4.5 17.0047C4.5 14.5203 6.51562 12.5047 9 12.5047H13.5V15.5047C13.5 16.0953 13.8469 16.6343 14.3906 16.8734C14.9344 17.1125 15.5625 17.014 16.0031 16.6203L23.5031 9.87028C23.8172 9.57965 24 9.17653 24 8.74997C24 8.32341 23.8172 7.92028 23.5031 7.63434L16.0031 0.884343C15.5625 0.485905 14.9297 0.387468 14.3906 0.631218Z"
               />
        </svg>

    )
}