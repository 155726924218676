import React from "react";

export const InquiryLineSvg: React.FC = () => {
    return (
        <svg width="360" height="336" viewBox="0 0 360 336" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-41.2395" y="76.1364" width="267.493" height="237.316" rx="20.5" stroke="#002042"
                  strokeOpacity="0.2" strokeWidth="25"/>
            <rect x="80.0071" y="-83.5" width="267.493" height="237.316" rx="20.5" stroke="#002042" strokeOpacity="0.1"
                  strokeWidth="25"/>
            <rect x="-66" y="48.5073" width="222.205" height="196.793" rx="17" stroke="#002042" strokeOpacity="0.5"
                  strokeWidth="18"/>
            <rect x="-66" y="213.731" width="183.916" height="163.269" rx="17" stroke="#002042" strokeOpacity="0.7"
                  strokeWidth="18"/>
        </svg>
    )
}