import {httpApi} from "@app/api/http.api";
import {ProductDomain} from "@app/domains/product.domain";

export interface RequestResult {
    success: boolean,
    message: string,
    code: number,
    data: number
}

export interface ReplyResult {
    success: boolean,
    message: string,
    code: number,
    data: ProductDomain
}

export const LikedComment = (id: number): Promise<RequestResult> =>
    httpApi.get<RequestResult>('comments/liked/' + id).then(({data}) => data);

export const DisLikedComment = (id: number): Promise<RequestResult> =>
    httpApi.get<RequestResult>('comments/disliked/' + id).then(({data}) => data);

export const ReplyQuestion = (id: number, text: string): Promise<ReplyResult> =>
    httpApi.post<ReplyResult>('comments/reply/' + id, {text: text}).then(({data}) => data);
