import React from "react";

export const AlertSvg:React.FC  = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33465 6.66663H8.66798V9.99996H7.33465V6.66663ZM7.33398 10.6666H8.66732V12H7.33398V10.6666Z"/>
            <path
                d="M9.17928 2.80004C8.94728 2.36337 8.49528 2.09204 8.00062 2.09204C7.50595 2.09204 7.05395 2.36337 6.82195 2.80071L1.92995 12.0427C1.8214 12.2457 1.76762 12.4734 1.77394 12.7035C1.78025 12.9336 1.84643 13.158 1.96595 13.3547C2.08375 13.5523 2.25104 13.7157 2.45132 13.8289C2.65159 13.9421 2.87792 14.0011 3.10795 14H12.8933C13.3653 14 13.7926 13.7587 14.0359 13.3547C14.1553 13.1579 14.2213 12.9335 14.2277 12.7035C14.234 12.4734 14.1803 12.2457 14.0719 12.0427L9.17928 2.80004ZM3.10795 12.6667L8.00062 3.42471L12.8966 12.6667H3.10795Z"/>
        </svg>
    )
}