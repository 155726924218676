import React from "react";

export const SendInquirySvg: React.FC = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1009_9022)">
                <path
                    d="M14.9 5.89997V5.9V6.66038L14.1 7.14038V4.93346L14.423 5.10527C14.5671 5.182 14.6876 5.2965 14.7717 5.43649C14.8557 5.57648 14.9001 5.73669 14.9 5.89997ZM8.05145 9.33125L8 9.30038L7.94855 9.33125L6.75 10.0504L3.1 7.86038V2.5C3.1 2.26131 3.19482 2.03239 3.3636 1.8636C3.53239 1.69482 3.76131 1.6 4 1.6H12C12.2387 1.6 12.4676 1.69482 12.6364 1.8636C12.8052 2.03239 12.9 2.26131 12.9 2.5V7.86038L9.25 10.0504L8.05145 9.33125ZM1.577 5.10627L1.57711 5.10621L1.9 4.93377V7.14038L1.1 6.66038V5.90007C1.10011 5.73695 1.14455 5.57692 1.22856 5.43711C1.31258 5.29729 1.43302 5.18293 1.577 5.10627ZM4 0.4C3.44305 0.4 2.9089 0.621249 2.51508 1.01508C2.12125 1.4089 1.9 1.94305 1.9 2.5V3.57301L1.01195 4.04676C1.01194 4.04676 1.01194 4.04677 1.01193 4.04677C0.676024 4.22588 0.395121 4.49293 0.199265 4.81936C0.0034065 5.14579 -0.100036 5.51932 -0.1 5.9C-0.1 5.9 -0.1 5.90001 -0.1 5.90001V14.5C-0.1 15.057 0.121249 15.5911 0.515076 15.9849C0.908902 16.3787 1.44305 16.6 2 16.6H14C14.557 16.6 15.0911 16.3787 15.4849 15.9849C15.8787 15.5911 16.1 15.057 16.1 14.5V5.9C16.1 5.51933 15.9966 5.14579 15.8007 4.81936C15.6049 4.49293 15.324 4.22588 14.9881 4.04677C14.9881 4.04677 14.9881 4.04676 14.988 4.04676L14.1 3.57301V2.5C14.1 1.94305 13.8787 1.4089 13.4849 1.01508C13.0911 0.621249 12.557 0.4 12 0.4H4ZM14 15.4H14H2C1.8024 15.4 1.6103 15.3349 1.45334 15.2149C1.31626 15.1101 1.21264 14.9684 1.1541 14.8072L7.99999 10.6996L14.8458 14.8081C14.7872 14.9691 14.6835 15.1106 14.5465 15.2153C14.3895 15.3351 14.1975 15.4 14 15.4ZM10.4164 10.75L14.9 8.05963V13.4404L10.4164 10.75ZM5.58362 10.75L1.1 13.4394V8.05963L5.58362 10.75Z"
                    strokeWidth="0.2"/>
            </g>
            <defs>
                <clipPath id="clip0_1009_9022">
                    <rect width="16" height="16" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    )
}